import React, { useEffect } from "react";
import {
  useScoreboardContext,
  formatScore,
} from "../../../contexts/ScoreboardContext";
import MapCards from "../MapCards";
import useTranslations from "../../../contexts/useTranslations";

function ScoreboardGraphic2(props) {
  const t = useTranslations();
  const {
    colors,
    setColors,
    timeIsRed,
    handleChangeColor,
    teamA,
    teamB,
    currentSport,
    time,
    currentScore,
    crest,
  } = useScoreboardContext();

  useEffect(() => {
    setColors((color) => {
      return { ...color, c1: "#353535", c2: "#fff", c3: "#353535", c4: "#fff" };
    });
  }, []);
  return (
    <div>
      <svg viewBox="0 0 401.081 89.461" {...props}>
        <path
          d="M188.078 48.315H19.676A19.676 19.676 0 010 28.638 19.676 19.676 0 0119.676 8.962h168.402z"
          fill="#282828"
        />

        <path
          d="M231.404 64.111h-61.727a12.675 12.675 0 00-12.675 12.675 12.675 12.675 0 0012.675 12.675h61.727a12.675 12.675 0 0012.675-12.675 12.675 12.675 0 00-12.675-12.675z"
          fill={timeIsRed ? "#f50505" : "#282828"}
        />
        <path
          d="M134.009 43.57H20.273A15.52 15.52 0 014.76 28.34h129.249zM134.009 13.11H20.273A15.52 15.52 0 004.76 28.34h129.249z"
          fill={colors.c1}
          onClick={(e) => handleChangeColor("c1", e)}
        />
        <path fill="#282828" d="M123.448 0h71.03v57.277h-71.03z" />
        <path fill="#353535" d="M129.316 4.248h59.2v22.676h-59.2z" />
        <path
          d="M213.003 48.315h168.402a19.676 19.676 0 0019.676-19.677 19.676 19.676 0 00-19.676-19.676H213.003z"
          fill="#282828"
        />
        <path
          d="M267.072 43.57h113.736a15.52 15.52 0 0015.514-15.23h-129.25zM267.072 13.11h113.736a15.52 15.52 0 0115.514 15.23h-129.25z"
          fill={colors.c3}
          onClick={(e) => handleChangeColor("c3", e)}
        />
        <path fill="#282828" d="M278.89 57.276h-72.287V0h72.288z" />
        <path fill="#353535" d="M272.929 26.924h-60.363V4.248h60.363z" />
        <text
          x={crest[0] === "" ? "65" : "77"}
          // x={65}
          y="36.123"
          textAnchor="middle"
          fontSize={17.746}
          fill={colors.c2}
          onClick={(e) => handleChangeColor("c2", e)}
          fontFamily="'Bebas Neue', cursive"
        >
          {teamA}
        </text>
        <text
          transform="translate(188 82.422)"
          fontSize={16.93}
          fill="#ddd"
          fontFamily="'Bebas Neue', cursive"
        >
          {time}
        </text>
        <svg
          width="500"
          height="70"
          transform="translate(100 0)"
          fill="transparent"
        >
          <text
            x="31.8%"
            y={currentSport === "f" ? "56%" : "59%"}
            textAnchor="middle"
            fontSize={
              currentSport === "s" || currentSport === "r" ? 40 : 33.041
            }
            fill="#ddd"
            fontFamily="'Bebas Neue', cursive"
          >
            {formatScore(currentScore, currentSport, 0)}
          </text>

          <text
            x="48%"
            y={currentSport === "f" ? "56%" : "59%"}
            textAnchor="middle"
            fontSize={
              currentSport === "s" || currentSport === "r" ? 40 : 33.041
            }
            fill="#ddd"
            fontFamily="'Bebas Neue', cursive"
          >
            {formatScore(currentScore, currentSport, 2)}
          </text>
        </svg>
        <text
          x={crest[1] === "" ? "335" : "325"}
          // x={335}
          y="36.123"
          textAnchor="middle"
          fontSize={17.746}
          onClick={(e) => handleChangeColor("c4", e)}
          fill={colors.c4}
          fontFamily="'Bebas Neue', cursive"
        >
          <tspan letterSpacing="-.003em">{teamB}</tspan>
        </text>
        <path fill="#ddd" d="M2.633 28.638h35.331M363.857 28.34h35.332" />

        <MapCards x={50} y={50} team={0} gap={[27, 8]} />

        <MapCards x={300} y={50} team={1} gap={[27, 8]} />
      </svg>
      {crest[0] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 1`}
          style={{
            position: "absolute",
            top: 455,
            left: 30,
            display: "box",
            width: 110,
            zAxis: 100,
          }}
          src={crest[0]}
        />
      )}
      {crest[1] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 2`}
          style={{
            position: "absolute",
            top: 455,
            left: 620,
            display: "box",
            width: 110,
            zAxis: 100,
          }}
          src={crest[1]}
        />
      )}
    </div>
  );
}

export default ScoreboardGraphic2;
