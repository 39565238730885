/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { usePoolContext } from "../../../hooks/PoolContext";
import ColorPicker from "../../ColorPicker";
import useTranslations from "../../../../contexts/useTranslations";

// eslint-disable-next-line react/prop-types
const PlayerName = ({ name = "Player A" }) => {
  // Split the sentence into the first word and the rest of the sentence
  const [firstWord, ...restOfSentence] = name.split(" ");

  return (
    <>
      <tspan style={{ opacity: 0.6 }}>{firstWord}</tspan>{" "}
      <tspan>{restOfSentence.join(" ")}</tspan>
    </>
  );
};
function PoolScoreboard2() {
  const t = useTranslations();

  const {
    teamA,
    teamB,
    crest,
    setTeamA,
    setTeamB,
    currentScore,
    colors,
    setColors,
    handleChangeColor,
  } = useScoreboardContext();

  const { fromA, fromB, raceTo, handicap, isHandicapEnabled, bottomLabel } =
    usePoolContext();

  useEffect(() => {
    if (teamA.length > 16) {
      setTeamA(teamA.slice(0, 16)); // Limit to 11 characters
    }
  }, [teamA]);

  useEffect(() => {
    if (teamB.length > 16) {
      setTeamB(teamB.slice(0, 16)); // Limit to 11 characters
    }
  }, [teamB]);

  useEffect(() => {
    setTeamA(t("noun:player") + " A");
    setTeamB(t("noun:player") + " B");
  }, []);

  // eslint-disable-next-line react/prop-types

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: 800,
          height: 250,
          paddingTop: 50,
          background: colors.sbBGColor,
          zIndex: -1,
        }}
        onClick={(e) => handleChangeColor("sbBGColor", e)}
      ></div>
      <ColorPicker />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 914.59 44.57"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1={426.98}
            x2={426.98}
            y1={22.34}
            y2={0}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#0a183d" />
            <stop offset={1} stopColor="#32427c" />
          </linearGradient>
          <linearGradient
            xlinkHref="#linear-gradient"
            id="linear-gradient-2"
            x1={487.03}
            x2={487.03}
            y1={22.34}
          />
          <style>
            {
              ".cls-6,.cls-9{font-size:15px;fill:#f2f2f2; font-family:Roboto; font-weight: 700}.cls-9{font-size:13px}"
            }
          </style>
        </defs>
        <g id="background">
          <path
            id="bottom-blue"
            d="m0 44.57 29.92-22.23L59.84.11h399.19v44.46H0Zm914.59 0L884.9 22.34 855.2.11H459.03v44.45h455.56Z"
            style={{
              fill: "#1e316d",
            }}
          />
          <path
            id="top-blue"
            d="M29.92 22.34 59.84.11h399.19L29.92 22.34Zm854.98 0L855.2.11H459.03L29.92 22.34H884.9Z"
            style={{
              fill: "#112465",
            }}
          />
          <path
            id="gr1"
            d="M404.24 0h45.48v22.34h-45.48z"
            style={{
              fill: "url(#linear-gradient)",
            }}
          />
          <path
            id="gr2"
            d="M464.29 0h45.48v22.34h-45.48z"
            style={{
              fill: "url(#linear-gradient-2)",
            }}
          />
        </g>
        <g id="text">
          <text
            style={{
              fontFamily: "Roboto",
              fontWeight: 700,
              fontSize: 17,
              fill: "#f2f2f2",
            }}
            transform="translate(454.18 16.5)"
          >
            <tspan x={0} y={0}>
              {"-"}
            </tspan>
          </text>
          <text
            style={{
              fontSize: 11,
              fontFamily: "Roboto",
              fontWeight: 700,
              fill: "#f2f2f2",
            }}
            textAnchor="middle"
            transform="translate(458 39.57)"
          >
            {bottomLabel ? <tspan>{bottomLabel + " | "}</tspan> : null}
            <tspan>{`${t("noun:race-to")} ${raceTo}`}</tspan>
          </text>
          <text className="cls-9" transform="translate(318.41 15.5)">
            <PlayerName name={teamA} />
          </text>
          <text className="cls-9" transform="translate(522.14 15.5)">
            <tspan x={0} y={0}>
              <PlayerName name={teamB} />
            </tspan>
          </text>
          <text className="cls-6" transform="translate(422.64 15.63)">
            <tspan x={0} y={0}>
              {currentScore[0]}
            </tspan>
          </text>
          <text className="cls-6" transform="translate(482.86 16.3)">
            <tspan x={0} y={0}>
              {currentScore[2]}
            </tspan>
          </text>
          {isHandicapEnabled ? (
            <text className="cls-6" transform="translate(102.12 16.5)">
              <tspan x={0} y={0}>
                {handicap?.[0] > 0 ? "+" : null}
                {handicap?.[0]}
              </tspan>
            </text>
          ) : null}
          {isHandicapEnabled ? (
            <text className="cls-6" transform="translate(797.58 16.3)">
              <tspan x={0} y={0}>
                {handicap?.[1] > 0 ? "+" : null}
                {handicap?.[1]}
              </tspan>
            </text>
          ) : null}
        </g>
      </svg>
    </>
  );
}

PoolScoreboard2.propTypes = {};

export default PoolScoreboard2;
