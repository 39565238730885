import React, { useEffect, useState } from "react";
import "../../assets/fonts/font.css";
import { setSize } from "./ScoreboardGraphic3";
import Slide from "react-reveal/Slide";
import MapCards from "../MapCards";
import {
  formatScore,
  useScoreboardContext,
} from "../../../contexts/ScoreboardContext";
import useTranslations from "../../../contexts/useTranslations";

function ScoreboardGraphic6() {
  const t = useTranslations();
  const [namehome, setTeamHome] = useState();
  const [nameaway, setTeamAway] = useState();

  const {
    currentScore,
    colors,
    setColors,
    teamA,
    teamB,
    time,
    timeIsRed,
    handleChangeColor,
    addedTime,
    currentSport,
    crest,
  } = useScoreboardContext();

  useEffect(() => {
    if (teamA) setTeamHome(setSize(teamA, 4));

    if (teamB) setTeamAway(setSize(teamB, 4));
  }, [teamA, teamB]);

  useEffect(() => {
    setColors((color) => {
      return { ...color, c1: "#000", c3: "#fff", c2: "#000", c4: "#fff" };
    });
  }, []);

  const addedTimeComponent = () => {
    return (
      // props.addedTime > 0 ?
      <Slide left duration={1500} when={addedTime > 0}>
        <div
          style={{
            width: 100,
            height: 53,
            backgroundColor: "#fff",
            position: "absolute",
            left: 600,
            top: 0,
            zIndex: -1,
          }}
        >
          <p
            className="text-center"
            style={{
              color: "#af2a2d",
              fontSize: 35,
              fontWeight: "bold",
              marginLeft: -5,
              marginTop: 0,
              fontFamily: "BebasNeue-Regular, Bebas Neue",
            }}
          >
            +{addedTime}
          </p>
        </div>
      </Slide>
    );
  };

  return (
    <div id="container_X" style={{ position: "relative" }}>
      <div style={{ zIndex: "20 !important" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 464 100">
          <defs>
            <style>
              {`
            .cls-1 {
              fill: none;
              stroke: #fff;
              stroke-miterlimit: 10;
              stroke-width: 2px;
            }
            
            .cls-2 {
              font-size: 28px;
              font-family: BebasNeue-Regular, Bebas Neue;
            }
            .cls-3{
              fill: #fff
            }
            `}
            </style>
          </defs>
          <title>scorebard-eirk</title>
          <g id="Layer_2" data-name="Layer 2">
            <rect
              x="1"
              y="1"
              width="137"
              height="38"
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e)}
            />
            <rect x="140" y="1" width="76" height="38" />
            <rect
              x="218"
              y="1"
              width="137"
              height="38"
              fill={colors.c2}
              onClick={(e) => handleChangeColor("c2", e)}
            />
            <rect
              x="357"
              y="1"
              width="105"
              height="38"
              fill={timeIsRed ? "#f50505" : "#000"}
            />
            <path
              className="cls-1"
              d="M70,80v38M286,80v38M425,80v38M208,80v38M532,80v38m1-38H69m0,39H533"
              transform="translate(-69 -79)"
            />
          </g>
          <g id="text">
            <text className="cls-2 cls-3" transform="translate(384.6 30)">
              {time}
            </text>
            <text
              className="cls-2 cls-3"
              textAnchor="middle"
              transform="translate(156 30)"
            >
              {formatScore(currentScore, currentSport, 0)}
            </text>
            <text className="cls-2 cls-3" transform="translate(173.9 30)">
              -
            </text>
            <text
              className="cls-2 cls-3"
              textAnchor="middle"
              transform="translate(196 30)"
            >
              {formatScore(currentScore, currentSport, 2)}
            </text>

            <text
              className="cls-2"
              fill={colors.c3}
              transform="translate(90 30)"
              textAnchor="middle"
              onClick={(e) => handleChangeColor("c3", e)}
            >
              {namehome}
            </text>
            <text
              className="cls-2"
              fill={colors.c4}
              transform="translate(260 30)"
              textAnchor="middle"
              onClick={(e) => handleChangeColor("c4", e)}
            >
              {nameaway}
            </text>
          </g>
        </svg>
      </div>

      <div
        style={{
          zoom: 1.8,
          MozTransform: "scale(1.8)",
          width: 500,
          height: 100,
          position: "absolute",
          top: 31,
          left: 42,
        }}
      >
        <MapCards x={0} y={0} team={0} gap={[30, 8]} />
      </div>

      <div
        style={{
          zoom: 1.8,
          MozTransform: "scale(1.8)",
          width: 500,
          height: 100,
          position: "absolute",
          top: 31,
          left: 154,
        }}
      >
        <MapCards x={0} y={0} team={1} gap={[30, 8]} />
      </div>

      {addedTimeComponent()}

      {crest[0] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 1`}
          style={{
            position: "absolute",
            top: 4,
            left: 5,
            display: "box",
            width: 76,
            height: 45,
            zAxis: 100,
            objectFit: "cover",
          }}
          src={crest[0]}
        />
      )}
      {crest[1] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 2`}
          style={{
            position: "absolute",
            top: 4,
            left: 385,
            display: "box",
            width: 76,
            height: 45,
            zAxis: 100,
            objectFit: "cover",
          }}
          src={crest[1]}
        />
      )}
    </div>
  );
}

export default ScoreboardGraphic6;
