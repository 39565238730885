import React from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { useTennisContext } from "../TennisContext";

function TennisBlueWhiteScoreboard() {
  const { teamA, teamB } = useScoreboardContext();

  const { sets, legs, currentScore, isFirstServer, notice, showNotice } =
    useTennisContext();

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1386.72 343.22"
    >
      <defs>
        <style>
          {
            ".cls-2{letter-spacing:-.07em}.cls-3,.cls-4,.cls-5{font-family:Roboto-Black,Roboto;font-size:90px}.cls-5,.cls-6{fill:#0062a2}.cls-7{fill:#e6e7e8}.cls-4,.cls-8{fill:#fff}.cls-9{letter-spacing:0}"
          }
        </style>
      </defs>
      <path
        className="cls-7"
        d="M13.68 76.59h1359.36c7.55 0 13.68 6.13 13.68 13.68v245.92c0 3.88-3.15 7.03-7.03 7.03H13.68C6.13 343.22 0 337.09 0 329.54V90.28C0 82.73 6.13 76.6 13.68 76.6Z"
      />
      {showNotice ? (
        <>
          <rect
            className="cls-7"
            width={509.71}
            height={101.59}
            rx={7.14}
            ry={7.14}
          />
          <text
            transform="translate(114.02 65.56)"
            style={{
              fontFamily: "Roboto-Medium,Roboto",
              fontSize: "55.32px",
            }}
          >
            <tspan x={0} y={0}>
              {notice}
            </tspan>
          </text>
        </>
      ) : null}

      <rect
        className="cls-6"
        x={18.6}
        y={88.96}
        width={767.35}
        height={114.58}
        rx={4.44}
        ry={4.44}
      />
      <rect
        className="cls-6"
        x={18.6}
        y={216.65}
        width={767.35}
        height={114.58}
        rx={4.46}
        ry={4.46}
      />
      <rect
        className="cls-8"
        x={799.75}
        y={88.96}
        width={152.46}
        height={114.58}
        rx={4.44}
        ry={4.44}
      />
      <rect
        className="cls-8"
        x={799.75}
        y={216.65}
        width={152.46}
        height={114.58}
        rx={4.44}
        ry={4.44}
      />
      <rect
        className="cls-8"
        x={966.56}
        y={88.96}
        width={152.46}
        height={114.58}
        rx={4.44}
        ry={4.44}
      />
      <rect
        className="cls-8"
        x={966.56}
        y={216.65}
        width={152.46}
        height={114.58}
        rx={4.44}
        ry={4.44}
      />
      <rect
        className="cls-6"
        x={1132.91}
        y={88.96}
        width={241.79}
        height={114.58}
        rx={4.44}
        ry={4.44}
      />
      <rect
        className="cls-6"
        x={1132.91}
        y={216.65}
        width={241.79}
        height={114.58}
        rx={4.44}
        ry={4.44}
      />
      {isFirstServer ? (
        <circle className="cls-8" cx={744.89} cy={146.25} r={11.34} />
      ) : null}
      <text className="cls-4" transform="translate(40.72 179.81)">
        <tspan x={0} y={0}>
          {teamA}
        </tspan>
      </text>
      <text className="cls-4" transform="translate(45.56 307)">
        <tspan x={0} y={0}>
          {teamB}
        </tspan>
      </text>
      <text className="cls-3" transform="translate(849.92 179.81)">
        <tspan x={0} y={0}>
          {sets[0]}
        </tspan>
      </text>
      <text className="cls-3" transform="translate(849.85 307)">
        <tspan x={0} y={0}>
          {sets[1]}
        </tspan>
      </text>
      <text className="cls-5" transform="translate(1016.7 180.71)">
        <tspan x={0} y={0}>
          {legs[0]}
        </tspan>
      </text>
      <text className="cls-5" transform="translate(1016.73 307)">
        <tspan x={0} y={0}>
          {legs[1]}
        </tspan>
      </text>
      <text
        className="cls-4"
        textAnchor="middle"
        transform="translate(1255 181.61)"
      >
        <tspan x={0} y={0}>
          {typeof currentScore?.[0] === "object" ? 0 : currentScore?.[0]}
        </tspan>
      </text>
      <text
        className="cls-4"
        textAnchor="middle"
        transform="translate(1255 307)"
      >
        <tspan x={0} y={0}>
          {typeof currentScore?.[1] === "object" ? 0 : currentScore?.[1]}
        </tspan>
      </text>

      {!isFirstServer ? (
        <circle className="cls-8" cx={744.89} cy={274.94} r={11.34} />
      ) : null}
    </svg>
  );
}

export default TennisBlueWhiteScoreboard;
