/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { usePoolContext } from "../../../hooks/PoolContext";
import ColorPicker from "../../ColorPicker";
import useTranslations from "../../../../contexts/useTranslations";

function PoolScoreboard2() {
  const t = useTranslations();

  const {
    teamA,
    teamB,
    crest,
    setTeamA,
    setTeamB,
    currentScore,
    colors,
    setColors,
    handleChangeColor,
  } = useScoreboardContext();

  const { fromA, fromB, raceTo } = usePoolContext();

  useEffect(() => {
    if (teamA.length > 16) {
      setTeamA(teamA.slice(0, 16)); // Limit to 11 characters
    }
  }, [teamA]);

  useEffect(() => {
    if (teamB.length > 16) {
      setTeamB(teamB.slice(0, 16)); // Limit to 11 characters
    }
  }, [teamB]);

  useEffect(() => {
    setTeamA(t("noun:player") + " A");
    setTeamB(t("noun:player") + " B");
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: 800,
          height: 250,
          paddingTop: 50,
          background: colors.sbBGColor,
          zIndex: -1,
        }}
        onClick={(e) => handleChangeColor("sbBGColor", e)}
      ></div>
      <ColorPicker />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        data-name="Layer 2"
        viewBox="0 0 416.5 171.8"
      >
        <path
          d="M43.12 41.8h312.65v46.47H43.12zM43.12 90.4h312.65v46.47H43.12z"
          style={{
            fill: "#373936",
            opacity: 0.85,
          }}
        />
        <text
          style={{
            fill: "#fff",
            fontFamily: "Roboto-Black,Roboto",
            fontSize: "31.49px",
          }}
          transform="translate(100.72 75.93)"
        >
          <tspan
            x={0}
            y={0}
            style={{
              letterSpacing: "-.04em",
              textTransform: "uppercase",
            }}
          >
            {teamA}
          </tspan>
        </text>
        <text
          style={{
            fill: "#fff",
            fontFamily: "Roboto-Black,Roboto",
            fontSize: "31.49px",
            textTransform: "uppercase",
          }}
          transform="translate(99.27 125.69)"
        >
          <tspan
            x={0}
            y={0}
            style={{
              letterSpacing: "-.04em",
            }}
          >
            {teamB}
          </tspan>
        </text>

        <text
          style={{
            fill: "#fff",
            fontFamily: "Roboto-Black,Roboto",
            fontSize: "31.49px",
          }}
          textAnchor="middle"
          transform="translate(325 75.93)"
        >
          <tspan x={0} y={0}>
            {currentScore[0]}
          </tspan>
        </text>
        <text
          style={{
            fill: "#fff",
            fontFamily: "Roboto-Black,Roboto",
            fontSize: "31.49px",
          }}
          textAnchor="middle"
          transform="translate(325 125.69)"
        >
          <tspan x={0} y={0}>
            {currentScore[2]}
          </tspan>
        </text>
        <text
          style={{
            fill: "#e8bd0a",
            fontFamily: "Roboto-BoldItalic,Roboto",
            fontSize: "18.45px",
          }}
          textAnchor="middle"
          transform="translate(199.5 159.1)"
        >
          <tspan x={0} y={0}>
            {`${t("noun:race-to")} ${raceTo}`}
          </tspan>
        </text>
      </svg>
      {crest[0] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 1`}
          style={{
            position: "absolute",
            top: 393,
            left: 108,
            display: "box",
            width: 85,
            height: 85,
            zAxis: 100,
            objectFit: "contain",
          }}
          src={crest[0]}
        />
      )}
      {crest[1] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 2`}
          style={{
            position: "absolute",
            top: 445,
            left: 108,
            display: "box",
            width: 85,
            height: 85,
            zAxis: 100,
            objectFit: "contain",
          }}
          src={crest[1]}
        />
      )}
    </>
  );
}

PoolScoreboard2.propTypes = {};

export default PoolScoreboard2;
