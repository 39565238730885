import { useCallback } from "react";
import { useIntl } from "react-intl";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const t = useCallback(
    (key, values) => {
      return formatMessage({ id: key, defaultMessage: key }, values);
    },
    [formatMessage]
  );

  return t;
};

export default useTranslations;
