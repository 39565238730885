import React, { useContext, useEffect, useState } from "react";
import { db, dbfs } from "../firebase";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { useAuth } from "./AuthContext";
import { stripeProducts } from "../scoreboard-app/components/Initialise_Settings";
import { ref, get, query } from "firebase/database";
import { collection, doc, getDoc, getDocs, where } from "firebase/firestore";

// [football,soccer,rugby,darts,tennis, pool, basketball]
let allSportsArray = [false, false, false, false, false, false, false];
// creates any cookie
export const createCookie = (cookieName, value, path, expiresInMonths) => {
  const cookie = new Cookies();
  let date = new Date();
  if (
    cookie.set(cookieName, value, {
      path: path,
      expires: new Date(date.setMonth(date.getMonth() + expiresInMonths)),
    })
  )
    return true;
  else return false;
};

// checks any cookie
export const verifyCookie = async (cookieName) => {
  const cookie = new Cookies();
  const cookieStatus = cookie.get(cookieName);

  if (cookieStatus) {
    return cookieStatus;
  } else {
    return false;
  }
};

export const parseTime = (time, setMinutes, setSeconds) => {
  let splitTime = time.split(/[.:]/);
  let min = parseInt(splitTime[0]);
  let sec = 0;

  if (splitTime.length > 1) sec = parseInt(splitTime[1]);
  if (sec >= 60) sec = 0;

  if (!isNaN(min) && !isNaN(sec) && min >= 0 && sec >= 0) {
    setSeconds(sec);
    setMinutes(min);
  } else {
    setSeconds(0);
    setMinutes(0);
  }
};

const SubscriptionContext = React.createContext();

export function useSubscription() {
  return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
  const [display, setDisplay] = useState();
  const [userInfo, setUserInfo] = useState();
  const [savedSettings, setSavedSettings] = useState();
  const [subscription, setSubscription] = useState();
  const [subscriptionPriceId, setSubscriptionPriceId] = useState();
  const [monitorCode, setMonitorCode] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [loadingSubscription, setLoadingSubscription] = useState(true);

  const { currentUser } = useAuth();

  // checks to see if user accepted cookie
  const checkCookie = async () => {
    const cookie = new Cookies();
    const cookieStatus = cookie.get("cookieAccept");

    if (cookieStatus) {
      setDisplay("none");
    } else {
      setDisplay("block");
    }
  };

  // handler for accepting cookie;
  // creates 30 day cookie
  const acceptCookie = (e) => {
    e.preventDefault();
    const cookies = new Cookies();
    let date = new Date();
    cookies.set("cookieAccept", true, {
      path: "/",
      expires: new Date(date.setMonth(date.getMonth() + 1)),
    });
    setDisplay("none");
  };

  const getProductType = (product_id) => {
    // gaa, soccer, rugby, darts
    let products = allSportsArray;

    console.log({ products });

    if (product_id) {
      switch (product_id.replace("products/", "")) {
        case stripeProducts.f.product_month: // gaa_month -- gaa_year
          products = [true, false, false, false, false];
          break;
        case stripeProducts.f.product_year: // gaa_month -- gaa_year
          products = [true, false, false, false, false];
          break;
        case stripeProducts.s.product_month: // soccer_month -- soccer_year
          products = [false, true, false, false, false, false];
          break;
        case stripeProducts.s.product_year: // soccer_month -- soccer_year
          products = [false, true, false, false, false, false];
          break;
        case stripeProducts.fsr.product_month: // soccer -- gaa- rugby
          products = [true, true, true, false, false, false];
          break;
        case stripeProducts.fsr.product_year:
          products = [true, true, true, false, false, false];
          break;
        case stripeProducts.r.product_month: // rugby_month
          products = [false, false, true, false, false, false];
          break;
        case stripeProducts.r.product_year: // rugby_year
          products = [false, false, true, false, false, false];
          break;
        case stripeProducts.d.product_year:
          products = [false, false, false, true, false, false];
          break;
        case stripeProducts.d.product_month:
          products = [false, false, false, true, false, false];
          break;
        case stripeProducts.t.product_year:
          products = [false, false, false, false, true, false];
          break;
        case stripeProducts.t.product_month:
          products = [false, false, false, false, true, false];
          break;
        case stripeProducts.p.product_month:
          products = [false, false, false, false, false, true];
          break;
        case stripeProducts.p.product_year:
          products = [false, false, false, false, false, true];
          break;
        case stripeProducts.all.product_year:
          products = [true, true, true, true, true, true, true];
          break;
        case stripeProducts.all.product_month:
          products = [true, true, true, true, true, true, true];
          break;
        // case stripeProducts.t.product_year:
        //   products = [false, false, false, false, true];
        //   break;
        // case stripeProducts.t.product_month:
        //   products = [false, false, false, false, true];
        //   break;
      }
    }
    return products;
  };

  useEffect(() => {
    if (currentUser?.uid && !stripeId) {
      let dbRef = ref(db, "customers/" + currentUser?.uid);

      get(dbRef)
        .then((snapshot) => {
          const snapVal = snapshot.val();
          if (snapVal) {
            setMonitorCode(snapVal?.monitor_id || "");
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Realtime Database:", error);
        });

      // Fetching data from Firestore
      const userDocRef = doc(dbfs, "customers", currentUser?.uid);

      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const stID = data?.stripeId;
            setStripeId(stID);
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Firestore:", error);
        });
    }
  }, [currentUser?.uid]);

  useEffect(async () => {
    try {
      // checks that firebase db exists
      if (dbfs) {
        // if user is logged in
        if (currentUser?.uid) {
          const customerRef = doc(dbfs, "customers", currentUser?.uid);
          const customerDoc = await getDoc(customerRef);

          if (customerDoc.exists()) {
            //  sets user info variable of users details in database
            // e.g. tokens, number of devices registered, etc.
            const userData = customerDoc.data();
            setUserInfo(userData);
            setSavedSettings(userData?.savedSettings);
          } else {
            setSubscription(false);
          }

          const subscriptionsRef = collection(customerRef, "subscriptions");
          const q = query(
            subscriptionsRef,
            where("status", "in", ["active", "trialing"])
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach(async (doc) => {
              if (doc.exists()) {
                // let status = await doc.data().status;
                // document[doc.id] = status;
                setSubscription(true);
                setLoadingSubscription(false);
                let pricePath = doc.data()?.price?.path;

                if (pricePath) {
                  let parts = pricePath?.split("/prices/");
                  if (parts.length === 2) {
                    setSubscriptionPriceId(parts[1]);
                    return;
                  }
                }
              }
            });
          } else {
            setSubscription(false);
          }
        }
        if (currentUser === null) setSubscription(false);
        setLoadingSubscription(false);
      }
    } catch (error) {
      setSubscription(false);
      setLoadingSubscription(false);
    }
  }, [currentUser]);

  const value = {
    userInfo,
    setUserInfo,
    display,
    checkCookie,
    acceptCookie,
    subscription,
    subscriptionPriceId,
    getProductType,
    monitorCode,
    setMonitorCode,
    stripeId,
    savedSettings,
    setSavedSettings,
    loadingSubscription,
    setLoadingSubscription,
    setSubscription,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}

SubscriptionProvider.propTypes = {
  children: PropTypes.any,
};
