import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { formatScore, useScoreboardContext } from '../../../contexts/ScoreboardContext';
import MapCards from '../MapCards';


function ScoreboardGraphic1() {
  const { 
    currentScore, 
    currentSport, 
    colors, setColors,
    teamA, teamB, 
    time, 
    timeIsRed,
    handleChangeColor
  } = useScoreboardContext();

  useEffect(() => {
    // let color = colors;
    setColors(color => { 
      return {...color, c1: "red", c3: "green", c4: "red"}
    })
  }, [])

  return (
    <div>
      <svg
          id="prefix__Layer_1"
          data-name="Layer 1"
          viewBox="0 0 401.081 89.461"
          // {...this.props}
        >
          <defs>
            <style>
              {
                ".prefix__cls-1{fill:#282828}.prefix__cls-2{fill:#353535}.prefix__cls-3{font-size:17.74591px}.prefix__cls-3,.prefix__cls-6,.prefix__cls-9{fill:#ddd}.prefix__cls-3,.prefix__cls-6{font-family: 'Bebas Neue', cursive}.prefix__cls-10{fill:#fff}"
              }
              {currentSport === "s" || currentSport === "r" 
                ? ".prefix__cls-6{font-size:40px}"
                : ".prefix__cls-6{font-size:33.04129px}"}
            </style>
          </defs>

          <path
            className="prefix__st2"
            d="M193 155H79.2c-8.5 0-15.4 6.8-15.5 15.2H193V155z"
          />

          <path
            className="prefix__cls-1"
            d="M255.363 86.03h-61.726a12.675 12.675 0 00-12.675 12.675 12.675 12.675 0 0012.675 12.675h61.726a12.675 12.675
             0 0012.675-12.675 12.675 12.675 0 00-12.675-12.675zM212.038 70.234H43.636A19.676 19.676 0 0123.96 50.557 19.676 
             19.676 0 0143.636 30.88h168.402z"
            transform="translate(-23.96 -21.919)"
          />

          {timeIsRed ? (
            <path
              //className="prefix__cls-1"
              d="M255.363 86.03h-61.726a12.675 12.675 0 00-12.675 12.675 12.675 12.675 0 0012.675 12.675h61.726a12.675 12.675
             0 0012.675-12.675 12.675 12.675 0 00-12.675-12.675zM212.038 70.234H43.636A19.676 19.676 "
              transform="translate(-23.96 -21.919)"
              fill="red"
            />
          ) : null}

          <path
            className="prefix__cls-2"
            d="M157.968 35.029H44.232a15.52 15.52 0 00-15.513 15.23h129.25z"
            transform="translate(-23.96 -21.919)"
          />
          <path className="prefix__cls-1" d="M123.448 0h71.03v57.277h-71.03z" />
          <path
            className="prefix__cls-2"
            d="M129.316 4.248h59.2v22.676h-59.2z"
          />
          <path
            className="prefix__cls-1"
            d="M236.963 70.234h168.401a19.676 19.676 0 0019.677-19.677 19.676 19.676 0 00-19.677-19.677H236.963z"
            transform="translate(-23.96 -21.919)"
          />
          <path
            className="prefix__cls-2"
            d="M291.032 35.029h113.736a15.52 15.52 0 0115.513 15.23H291.032z"
            transform="translate(-23.96 -21.919)"
          />
          <path
            className="prefix__cls-1"
            transform="rotate(180 254.727 39.598)"
            d="M230.563 21.919h72.288v57.277h-72.288z"
          />
          <path
            className="prefix__cls-2"
            transform="rotate(180 254.727 26.546)"
            d="M236.525 26.167h60.363v22.676h-60.363z"
          />
          <text 
            x="80"
            y="36.123"
            textAnchor="middle"
            className="prefix__cls-3">
            {teamA}
          </text>
          <text
            transform="translate(190 83)"
            fontSize={16.93}
            fontFamily="Bebas Neue, cursive"
            fill="#ddd"
          >
            {time}
          </text>

          <text
            className="prefix__cls-6"
            textAnchor="middle"
            x="160"
            y={currentSport === "f" ? 40 : 42}
            
          >
            {formatScore(currentScore, currentSport, 0)}
          </text>

          <text
            className="prefix__cls-6"
            textAnchor="middle"
            x="242"
            y={currentSport === "f" ? 40 : 42}
          >
            {formatScore(currentScore, currentSport, 2)}
          </text>
          <text 
          x="322"
          y="36.123"
          textAnchor="middle"
          className="prefix__cls-3" 
          >
            <tspan letterSpacing="-.003em">{teamB}</tspan>
          </text>
          <path
            d="M29.755 40.527a17.487 17.487 0 0028.65 20.06"
            transform="translate(-23.96 -21.919)"
            fill={colors.c2} // color 2 - home
            onClick={(e) => handleChangeColor("c2", e)}
          />

          <path
            d="M58.405 60.587a17.487 17.487 0 10-28.65-20.06z"
            transform="translate(-23.96 -21.919)"
            fill={colors.c1} // color 1 - home
            onClick={(e) => handleChangeColor("c1", e)}
          />
          <path className="prefix__cls-9" d="M2.633 28.638h35.331" />
          <path
            d="M390.98 40.229a17.487 17.487 0 1028.65 20.06"
            transform="translate(-23.96 -21.919)"
            fill={colors.c4} //
            onClick={(e) => handleChangeColor("c4", e)} // color-away-1
          />
          <path
            d="M419.63 60.29a17.487 17.487 0 10-28.65-20.061z"
            transform="translate(-23.96 -21.919)"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e)} // color-away-2
          />
          <path className="prefix__cls-9" d="M363.857 28.34h35.332" />
         
          <MapCards
            x={50}
            y={50}
            team={0} 
            gap={[27,8]}
          />
          
          <MapCards 
            x={300}
            y={50}
            team={1} 
            gap={[27,8]}
          />
        </svg>
    </div>
  );
}

export default ScoreboardGraphic1;