/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useBasketballContext } from "../../Basketball/BasketballContext";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { setSize } from "../ScoreboardGraphic3";

function BasketballScoreboard2() {
  const {
    scoreA,
    scoreB,
    period,
    announcement,
    showAnnouncement,
    currentTeam,
    hasShotClock,
    shotClockTime,
    foulsACount,
    foulsBCount,
    showFoulsCounter,
    numFoulsPerPeriod,
  } = useBasketballContext();

  const {
    teamA,
    teamB,
    setTeamA,
    setTeamB,
    time,
    crest,
    colors,
    handleChangeColor,
    setColors,
  } = useScoreboardContext();

  const rectRef = useRef(null);

  useEffect(() => {
    setTeamA("Team A");
    setTeamB("Team B");
  }, []);

  useEffect(() => {
    setColors((_colors) => {
      let temp = {
        ...colors,
        c1: "#d42042",
        c2: "#0bb5dd",
        c3: "#fff",
        c4: "#fff",
      };
      return temp;
    });
  }, []);

  const [isExpanded, setIsExpanded] = useState(undefined);

  useEffect(() => {
    if (showAnnouncement !== undefined) toggleClip(showAnnouncement);
  }, [showAnnouncement]);

  const toggleClip = (notExpanded) => {
    setIsExpanded(notExpanded);
  };

  // Calculate the total width and spacing
  const totalWidth = 250;
  const spaceWidth = totalWidth / numFoulsPerPeriod;
  const width = spaceWidth * (3 / 4);
  const spacing = spaceWidth * (1 / 4);

  // Generate the paths with dynamic spacing
  const paths = useMemo(() => {
    const elements = [];

    if (!showFoulsCounter) return elements;

    for (let i = 0; i < numFoulsPerPeriod; i++) {
      const translateX = -208 + i * (width + spacing);
      elements.push(
        <rect
          key={i}
          x={343}
          y={127}
          width={width}
          height={8}
          fill={foulsACount > i ? "red" : "white"}
          transform={`translate(${translateX} 0)`}
        />
      );
    }
    return elements;
  }, [numFoulsPerPeriod, width, spacing, foulsACount, showFoulsCounter]);

  const path2 = useMemo(() => {
    const elements = [];

    if (!showFoulsCounter) return elements;

    for (let i = 0; i < numFoulsPerPeriod; i++) {
      const translateX = 5 + i * (width + spacing);
      elements.push(
        <rect
          key={i}
          x={1162}
          y={127}
          width={width}
          height={8}
          fill={foulsBCount > i ? "red" : "white"}
          transform={`translate(${translateX} 0)`}
        />
      );
    }
    return elements;
  }, [numFoulsPerPeriod, width, spacing, foulsBCount, showFoulsCounter]);

  return (
    <>
      <style>
        {`.show-scoreboard {
          opacity: 1;
          transform: translateY(0);
          transition:  transform 0.5s ease-in-out;
          }

          .hide-scoreboard {
            opacity: 1;
            transform: translateY(40%);
            transition: transform 0.5s ease-in-out;
            pointer-events: none;
          } 
          `}
      </style>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1553 146"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1={249.5}
            x2={249.5}
            y1={32}
            y2={2}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#1d7cff" />
            <stop offset={0.48} stopColor="#2d5eba" />
            <stop offset={1} stopColor="#0e347b" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1={778.5}
            x2={778.5}
            y1={97}
            y2={36}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#525453" />
            <stop offset={0.4} stopColor="#282726" />
            <stop offset={0.71} stopColor="#201f1e" />
            <stop offset={0.99} stopColor="#434343" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1={1536.5}
            x2={1536.5}
            y1={97}
            y2={36}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#525453" />
            <stop offset={0.4} stopColor="#282726" />
            <stop offset={0.71} stopColor="#201f1e" />
            <stop offset={0.99} stopColor="#434343" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1={1522.49}
            x2={1522.51}
            y1={97}
            y2={36}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#525453" />
            <stop offset={0.4} stopColor="#282726" />
            <stop offset={0.71} stopColor="#201f1e" />
            <stop offset={0.99} stopColor="#434343" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            x1={16.5}
            x2={16.5}
            y1={97}
            y2={36}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#525453" />
            <stop offset={0.4} stopColor="#282726" />
            <stop offset={0.71} stopColor="#201f1e" />
            <stop offset={0.99} stopColor="#434343" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-6"
            x1={32.5}
            x2={32.5}
            y1={97}
            y2={36}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#525453" />
            <stop offset={0.4} stopColor="#282726" />
            <stop offset={0.71} stopColor="#201f1e" />
            <stop offset={0.99} stopColor="#434343" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-7"
            x1={1162.89}
            x2={1162.89}
            y1={90}
            y2={43}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#e42e18" />
            <stop offset={0.4} stopColor="#cb2b18" />
            <stop offset={1} stopColor="#5d010a" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-8"
            x1={778.5}
            x2={778.5}
            y1={148}
            y2={101}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#f5f5f5" />
            <stop offset={0.48} stopColor="#eee" />
            <stop offset={1} stopColor="#d9d9d9" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-9"
            x1={462.5}
            x2={462.5}
            y1={32}
            y2={2}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#1d7cff" />
            <stop offset={0.48} stopColor="#2d5eba" />
            <stop offset={1} stopColor="#0e347b" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-10"
            x1={1284.5}
            x2={1284.5}
            y1={32}
            y2={2}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#1d7cff" />
            <stop offset={0.48} stopColor="#2d5eba" />
            <stop offset={1} stopColor="#0e347b" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-11"
            x1={394}
            x2={394}
            y1={90}
            y2={43}
            gradientTransform="matrix(1 0 0 -1 0 148)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#e42e18" />
            <stop offset={0.4} stopColor="#cb2b18" />
            <stop offset={1} stopColor="#5d010a" />
          </linearGradient>
          <linearGradient
            xlinkHref="#linear-gradient"
            id="linear-gradient-12"
            x1={1071.5}
            x2={1071.5}
          />
          <style>
            {
              '.cls-5{letter-spacing:0}.cls-10,.cls-11,.cls-7,.cls-8{fill:#fff}.cls-8{font-size:40px}.cls-10,.cls-11,.cls-8{isolation:isolate}.cls-10,.cls-8{font-family:Airstrike,Airstrike}.cls-13{letter-spacing:0}.cls-11{font-family:TitilliumWeb-Regular,"Titillium Web"}.cls-10{font-size:18px}.cls-11{font-size:28px}.cls-27{letter-spacing:0}'
            }
          </style>
        </defs>
        <g id="Layer_1">
          <g
            ref={rectRef}
            className={isExpanded ? "show-scoreboard" : "hide-scoreboard"}
          >
            <path
              d="M428 0h718l-17 47H411l17-47z"
              style={{
                fill: "url(#linear-gradient-8)",
              }}
            />
            <text
              style={{
                fontFamily: "TitilliumWeb-Regular,&quot",
                fill: "#140202",
                fontSize: 28,
                isolation: "isolate",
              }}
              transform="translate(780 33)"
              textAnchor="middle"
            >
              <tspan x={0} y={0}>
                {announcement
                  ? announcement +
                    " BY " +
                    (currentTeam === 0
                      ? teamA.toUpperCase()
                      : teamB.toUpperCase())
                  : null}
              </tspan>
            </text>
          </g>
          {showFoulsCounter ? (
            <path
              d="M103 116h304l-11 30H92l11-30z"
              style={{
                fill: "url(#linear-gradient)",
              }}
            />
          ) : null}
          <path
            d="M55 51h1470l-23 61H32l23-61z"
            style={{
              fill: "url(#linear-gradient-2)",
            }}
          />
          <path
            d="M1543 51h10l-23 61h-10l23-61Z"
            style={{
              fill: "url(#linear-gradient-3)",
            }}
          />
          <path
            d="M1531 51h6l-23 61h-6l23-61Z"
            style={{
              fill: "url(#linear-gradient-4)",
            }}
          />
          <path
            d="M23 51h10l-23 61H0l23-61Z"
            style={{
              fill: "url(#linear-gradient-5)",
            }}
          />
          <path
            d="M41 51h6l-23 61h-6l23-61Z"
            style={{
              fill: "url(#linear-gradient-6)",
            }}
          />
          <path
            d="M1011 58h321.77L1315 105H993l18-47z"
            style={{
              fill: "url(#linear-gradient-7)",
            }}
          />
          {showFoulsCounter ? (
            <path
              d="M1138 116h304l-11 30h-304l11-30z"
              style={{
                fill: "url(#linear-gradient-10)",
              }}
            />
          ) : null}
          {path2}
          <path
            d="M242.11 58h321.78l-17.78 47h-322l18-47z"
            style={{
              fill: "url(#linear-gradient-11)",
            }}
          />
          {foulsBCount >= numFoulsPerPeriod ? (
            <>
              <path
                d="M418 116h100l-11 30H407l11-30z"
                style={{
                  fill: "url(#linear-gradient-9)",
                }}
              />
              <text className={`cls-10`} transform="translate(430.34 135.23)">
                <tspan x={0} y={0}>
                  {"BONUS"}
                </tspan>
              </text>
            </>
          ) : null}
          {foulsACount >= numFoulsPerPeriod ? (
            <>
              <path
                d="M1027 116h100l-11 30h-100l11-30z"
                style={{
                  fill: "url(#linear-gradient-12)",
                }}
              />
              <text className="cls-10" transform="translate(1039.34 135.23)">
                <tspan x={0} y={0}>
                  {"BONUS"}
                </tspan>
              </text>
            </>
          ) : null}
        </g>
        <g id="Layer_3">{paths}</g>
        <g id="xs">
          <image xlinkHref={crest[0]} x="65" y="54" width="54" height="54" />
          <image xlinkHref={crest[1]} x="1430" y="54" width="54" height="54" />
        </g>
        <g id="Layer_2">
          <text className="cls-11" transform="translate(645.75 90.93)">
            <tspan x={0} y={0}>
              {period}
            </tspan>
          </text>
          <text
            style={{
              fontFamily: "Oswald-SemiBold,Oswald",
              isolation: "isolate",
              fontSize: 40,
              fill: "#fff",
            }}
            transform="translate(731.72 98.58)"
          >
            <tspan x={0} y={0}>
              {time}
            </tspan>
          </text>
          {hasShotClock ? (
            <text className="cls-11" transform="translate(876.33 90.93)">
              <tspan x={0} y={0}>
                {shotClockTime}
              </tspan>
            </text>
          ) : null}
          <text
            className="cls-8"
            transform="translate(1158 93.12)"
            textAnchor="middle"
          >
            <tspan x={0} y={0}>
              {teamB}
            </tspan>
          </text>
          <text
            className="cls-8"
            transform="translate(1375 93.12)"
            textAnchor="middle"
          >
            <tspan x={0} y={0}>
              {scoreB}
            </tspan>
          </text>
          <text
            className="cls-8"
            transform="translate(175.36 93.12)"
            textAnchor="middle"
          >
            <tspan x={0} y={0}>
              {scoreA}
            </tspan>
          </text>

          <text
            className="cls-8"
            transform="translate(386.66 93.12)"
            textAnchor="middle"
          >
            <tspan x={0} y={0}>
              {teamA}
            </tspan>
          </text>
        </g>
      </svg>
    </>
  );
}

BasketballScoreboard2.propTypes = {};

export default BasketballScoreboard2;
