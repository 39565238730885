/* eslint-disable */
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useScoreboardContext } from "../../contexts/ScoreboardContext";
import { sportsSettings } from "../components/Initialise_Settings";
import { db } from "../../firebase";
import { ref, onValue, set, update } from "firebase/database";
import { doc, updateDoc, deleteField, setDoc } from "firebase/firestore";
import { dbfs } from "../../firebase";
import useTranslations from "../../contexts/useTranslations";
import { useSubscription } from "../../contexts/SubscriptionContext";

const DartsContext = createContext();

export function useDartsContext() {
  return useContext(DartsContext);
}

export const DartsProvider = ({ children }) => {
  const t = useTranslations();
  const {
    currentSport,
    currentScoreboard,
    setCurrentScoreboard,
    teamA,
    setTeamA,
    teamB,
    setTeamB,
    generatedCode,
    setGeneratedCode,
    isPhoneController,
    currentUser,
    enableFB,
    setEnableFB,
    invalidCode,
    setInvalidCode,
    enableListener,
    setEnableListener,
    colors,
    setColors,
    setCurrentSport,
  } = useScoreboardContext();
  const { savedSettings, setSavedSettings } = useSubscription();

  let value;

  const [competitionName, setCompetitionName] = useState(
    t("noun:competition-name")
  );
  const [firstTo, setFirstTo] = useState(7);
  const [isFirstTo, setIsFirstTo] = useState(true);
  const [isFirstToLegs, setIsFirstToLegs] = useState(true);
  const [firstToLegs, setFirstToLegs] = useState(3);
  const [firstToLegsOG, setFirstToLegsOG] = useState(firstToLegs);
  const [showModalSettings, setShowModalSettings] = useState(false);
  const [showModalSettingsRemote, setShowModalSettingsRemote] = useState(false);
  const [checkout, setCheckout] = useState(501);
  const [currentScore, setCurrentScore] = useState([501, 501]);
  const [firstPlayer, setFirstPlayer] = useState(1);
  const [currentPlayer, setCurrentPlayer] = useState(firstPlayer);
  const [currentRound, setCurrentRound] = useState(1);
  const [currentScoreInput, setCurrentScoreInput] = useState("");
  const [noScore, setNoScore] = useState(true);
  const [bustFlag, setBustFlag] = useState(false);
  const [legs, setLegs] = useState([0, 0]);
  const [sets, setSets] = useState([0, 0]);
  const [showFinish, setShowFinish] = useState(false);
  const [delayedPlayer, setDelayedPlayer] = useState(1);
  // const [enableFB, setEnableFB] = useState(false);
  // const [enableListener, setEnableListener] = useState(false);
  // const [invalidCode, setInvalidCode] = useState();
  const [undoHistory, setUndoHistory] = useState([
    {
      score: currentScore,
      legs: [0, 0],
      currentPlayer: currentPlayer,
      firstPlayer: firstPlayer,
    },
  ]);
  const [isRemote, setIsRemote] = useState(false);

  const hasSets = useMemo(() => {
    if (currentScoreboard)
      return sportsSettings.d.scoreboards[currentScoreboard].hasSets;
    else return false;
  }, [currentScoreboard]);

  useEffect(() => {
    if (currentSport === "d" && enableFB) {
      initiateDBListener(generatedCode);
    }
  }, [currentSport]);

  const undo = () => {
    const undoHistoryLength = undoHistory.length;

    if (undoHistoryLength > 1) {
      const lastHistory = undoHistory[undoHistoryLength - 1];
      let _legs = lastHistory.legs;
      let _currentPlayer = lastHistory.currentPlayer;
      let _firstPlayer = lastHistory.firstPlayer;
      let _currentScore = lastHistory.score;
      let _sets = lastHistory.sets;
      let _showFinish = false;
      let _delayedPlayer = _currentPlayer;

      setCurrentScore([..._currentScore]);
      setLegs(_legs);
      setSets(_sets);
      setCurrentPlayer(_currentPlayer);
      setFirstPlayer(_firstPlayer);

      // if (_currentScore[_currentPlayer - 1] <= 170) {
      //   _showFinish = true;
      //   setDelayedPlayer(_currentPlayer);
      //   setShowFinish(_showFinish);
      // } else {
      setShowFinish(false);

      updateFB({
        legs: _legs,
        sets: _sets,
        currentScore: _currentScore,
        firstPlayer: _firstPlayer,
        currentPlayer: _currentPlayer,
        delayedPlayer: _delayedPlayer,
        showFinish: _showFinish,
      });

      setUndoHistory((hist) => {
        let temp = [...hist];
        temp.pop();
        return temp;
      });
    } else {
      setCurrentPlayer(1);
      setLegs([0, 0]);
      setSets([0, 0]);
      setCurrentScore([checkout, checkout]);
      setFirstPlayer(1);

      updateFB({
        legs: [0, 0],
        sets: [0, 0],
        currentScore: [checkout, checkout],
        firstPlayer: 1,
        currentPlayer: 1,
        delayedPlayer: 1,
        showFinish: false,
      });
    }
  };

  const getFirstTo = () => {
    let _legs = [...legs];
    let _sets = [...sets];
    if (legs[currentPlayer - 1] + 1 >= firstToLegs && hasSets) {
      _legs = [0, 0];
      _sets[currentPlayer - 1]++;
      setLegs(_legs);
      setSets(_sets);
    } else {
      _legs[currentPlayer - 1]++;
      setLegs(_legs);
    }

    return { legs: _legs, sets: _sets };
  };

  const bestOf = () => {
    let cPlayer = currentPlayer - 1;
    let otherPlayer = cPlayer === 0 ? 1 : 0;
    let l1 = legs[cPlayer] + 1;
    let l2 = legs[otherPlayer];
    let _legs = [...legs];
    let _sets = [...sets];

    if (l1 > firstToLegs - l1 && hasSets) {
      _legs = [0, 0];
      _sets[currentPlayer - 1]++;

      setLegs(_legs);
      setSets(_sets);
    } else if (l2 > firstToLegs - l2 && hasSets) {
      _legs = [0, 0];
      _sets[currentPlayer - 1]++;
      setLegs(_legs);
      setSets(_sets);
    } else {
      _legs[currentPlayer - 1]++;
      setLegs(_legs);
    }

    return { legs: _legs, sets: _sets };
  };

  const clr = () => {
    setCurrentScoreInput("");
  };

  const handleChangeCheckout = (value) => {
    setCheckout(value);
    setCurrentScore([value, value]);
    if (enableFB)
      updateFB({
        checkout: value,
        currentScore: [value, value],
      });
  };

  const handleScoreInput = (e) => {
    e.preventDefault();
    let val = e.target.value;
    setCurrentScoreInput(val);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAdd();
    }
  };

  const addDigitToScoreInput = (worth) => {
    setCurrentScoreInput((score) => {
      return score + worth;
    });
  };

  // const handleNoScore = () => {
  //   handleAdd();
  // };

  const addHistory = () => {
    setUndoHistory((hist) => {
      let temp = [...hist];
      // let newCurrentScore = currentScore;
      // newCurrentScore[currentPlayer-1] = _score;
      temp.push({
        score: [...currentScore],
        legs: [...legs],
        sets: [...sets],
        currentPlayer: currentPlayer,
        firstPlayer: firstPlayer,
      });

      return temp;
    });
  };

  const handleAdd = () => {
    let _score;
    let _otherPlayer = currentPlayer == 1 ? 2 : 0;
    let _currentScore = [...currentScore];
    let _legs = [...legs];
    let _sets = [...sets];
    let _firstPlayer = firstPlayer;
    let _currentPlayer = currentPlayer;
    let _showFinish = false;
    setShowFinish(false);

    if (currentScoreInput)
      _score = currentScore[currentPlayer - 1] - parseInt(currentScoreInput);
    else _score = currentScore[currentPlayer - 1];

    let busted = _score === 1 || _score < 0;

    if (busted) {
      setBustFlag(true);
    } else {
      setBustFlag(false);
    }
    if (_score === 0) {
      if (isFirstToLegs) {
        let { legs, sets } = getFirstTo();
        _legs = legs;
        _sets = sets;
      } else {
        let { legs, sets } = bestOf();
        _legs = legs;
        _sets = sets;
      }

      _firstPlayer = firstPlayer === 1 ? 2 : 1;
      _currentPlayer = _firstPlayer;

      _showFinish = false;
      setShowFinish(false);
      setCurrentPlayer(_currentPlayer);
      setFirstPlayer(_firstPlayer);
      setCurrentRound((round) => round + 1);
    }

    if (!busted) {
      if (_score === 0) {
        _currentScore = [checkout, checkout];
      } else {
        _currentScore[currentPlayer - 1] = _score;
      }

      setCurrentScore(_currentScore);
    }

    setCurrentScoreInput("");
    if (_score !== 0) {
      _currentPlayer = _currentPlayer === 1 ? 2 : 1;
      setCurrentPlayer(_currentPlayer);
    }

    if (_showFinish) {
      _showFinish = false;
      setShowFinish(_showFinish);
    }

    if (currentScore[_currentPlayer - 1] <= 170) {
      if (delayedPlayer === undefined) setDelayedPlayer(_currentPlayer);
      setShowFinish(false);
      setTimeout(() => {
        _showFinish = true;
        updateFB({ showFinish: _showFinish, delayedPlayer: _currentPlayer });
        setDelayedPlayer(_currentPlayer);
        setShowFinish(_showFinish);
      }, 1000);
    }

    if (enableFB)
      updateFB({
        currentPlayer,
        currentScore: _currentScore,
        legs: _legs,
        sets: _sets,
        currentPlayer: _currentPlayer,
        firstPlayer: _firstPlayer,
        showFinish: _showFinish,
      });

    addHistory();
  };

  const handleShowSettings = () => {
    setShowModalSettings(true);
  };

  const handleShowSettingsRemote = () => {
    setShowModalSettingsRemote(true);
  };

  const handleTextBox = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      console.log("enter press here! ");
    }
  };

  const changeFirstTo = (value) => {
    setFirstToLegs(value);
    setFirstToLegsOG(value);
  };

  const updateFB = (updateObject) => {
    const scoresRef = ref(db, `scores/${currentUser.uid}/${generatedCode}`);
    update(scoresRef, updateObject);
  };

  const handleSaveSettings = async () => {
    const userDocRef = doc(dbfs, "customers", currentUser.uid);

    const data = {
      savedSettings: {
        competitionName,
        firstTo,
        isFirstTo,
        isFirstToLegs,
        firstToLegsOG,
        checkout,
        firstPlayer,
        colors,
        generatedCode,
        currentScoreboard,
      },
    };

    await setDoc(userDocRef, data, { merge: true });

    setSavedSettings((prev) => {
      return {
        competitionName,
        firstTo,
        isFirstTo,
        isFirstToLegs,
        firstToLegsOG,
        checkout,
        firstPlayer,
        colors,
        generatedCode,
        currentScoreboard,
      };
    });
  };

  const handleResetSettings = async () => {
    setColors((cols) => {
      return { ...cols, c1: "#8f0102", c2: "#fff", c3: "#1b9714", c4: "#000" };
    });
    setCompetitionName(t("noun:competition-name"));
    setFirstTo(7);
    setIsFirstTo(true);
    setIsFirstToLegs(true);
    setFirstToLegsOG(3);
    setFirstToLegs(3);
    setCheckout(501);
    setFirstPlayer(1);
    setGeneratedCode("Generate Code");

    setSavedSettings({
      competitionName: t("noun:competition-name"),
      isFirstTo: true,
      checkout: 501,
      generatedCode: "Generar Código",
      isFirstToLegs: true,
      colors: {
        c1: "#8f0102",
        color: {
          b: 0,
          g: 0,
          r: 0,
        },
        sbBGColor: "",
        c2: "#fff",
        c3: "#1b9714",
        c4: "#000",
        currentColor: "",
      },
      firstTo: 7,
      firstToLegsOG: 3,
      firstPlayer: 1,
    });

    const userDocRef = doc(dbfs, "customers", currentUser.uid);

    await updateDoc(userDocRef, {
      savedSettings: deleteField(),
    });
  };

  const initiateDB = (code) => {
    setEnableFB(true);
    ref(db, `scores/${currentUser.uid}/${code}`);
    const scoresRef = ref(db, `scores/${currentUser.uid}/${code}`);

    set(scoresRef, {
      competitionName,
      firstTo,
      isFirstTo,
      firstToLegs,
      isFirstToLegs,
      firstToLegsOG,
      sport: currentSport,
      currentScore,
      currentScoreboard,
      teamA,
      teamB,
      legs,
      sets,
      checkout,
      firstPlayer,
      currentPlayer,
      currentRound,
      delayedPlayer,
      showFinish,
    });
  };

  useEffect(() => {
    if (enableFB) updateFB({ currentScoreboard: currentScoreboard });
  }, [currentScoreboard, enableFB]);

  useEffect(() => {
    let saved = savedSettings;

    if (saved && !isRemote && currentSport === "d") {
      const {
        competitionName,
        firstTo,
        isFirstTo,
        isFirstToLegs: iftl,
        firstToLegsOG: og,
        checkout: ch,
        firstPlayer: fp,
        colors: cols,
        generatedCode: genc,
      } = saved;

      setCompetitionName(competitionName);
      setFirstTo(firstTo);
      setIsFirstTo(isFirstTo);
      setIsFirstToLegs(iftl);
      setFirstToLegsOG(og);
      setFirstToLegs(og);
      setCheckout(ch);
      setFirstPlayer(fp);
      setColors(cols);
      // setGeneratedCode(genc);
      // initiateDB(genc);
      setCurrentScore([ch, ch]);
    }
  }, [savedSettings, currentSport]);

  const initiateDBListener = async (genCode) => {
    const code = genCode || generatedCode;
    setGeneratedCode(code);
    let dbRef;

    try {
      dbRef = ref(db, "scores/" + currentUser.uid + "/" + code);

      const subscription = onValue(dbRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log("listening darts");
          if (data?.competitionName) setCompetitionName(data.competitionName);
          if (data?.currentSport) setCurrentSport(data.currentSport);
          if (data?.firstTo !== undefined) setFirstTo(data.firstTo);
          if (data?.isFirstTo !== undefined) setIsFirstTo(data.isFirstTo);
          if (data?.firstToLegs !== undefined) setFirstToLegs(data.firstToLegs);
          if (data?.isFirstToLegs !== undefined)
            setIsFirstToLegs(data.isFirstToLegs);
          if (data?.firstToLegsOG) setFirstToLegsOG(data.firstToLegsOG);
          if (data?.currentScore && data?.currentScore.length === 2)
            setCurrentScore(data.currentScore);
          if (data?.teamA) setTeamA(data.teamA);
          if (data?.teamB) setTeamB(data.teamB);
          if (data?.legs && data?.legs.length === 2) setLegs(data.legs);
          if (data?.sets && data?.sets.length === 2) setSets(data.sets);
          if (data?.currentScoreboard !== undefined)
            setCurrentScoreboard(data.currentScoreboard);
          if (data?.checkout) setCheckout(data.checkout);
          if (data?.firstPlayer !== undefined) setFirstPlayer(data.firstPlayer);
          if (data?.currentPlayer !== undefined)
            setCurrentPlayer(data.currentPlayer);
          if (data?.currentRound !== undefined)
            setCurrentRound(data.currentRound);
          setEnableListener(true); // No need for if statement, as it's always true
          setEnableFB(true); // No need for if statement, as it's always true
          setInvalidCode(false); // No need for if statement, as it's always false
          if (data?.showFinish !== undefined) setShowFinish(data.showFinish);
          if (data?.delayedPlayer !== undefined)
            setDelayedPlayer(data.delayedPlayer);
          if (data?.currentScoreboard)
            setCurrentScoreboard(data.currentScoreboard);

          // if (bcast != null) bcast.postMessage(["score", data.score]);
        } else {
          setInvalidCode(true);
        }
      });

      return subscription;
    } catch (e) {
      console.log("error caught");
    }

    return () => {};
  };

  const scores = [
    { worth: t("noun:undo"), varient: "info", width: "30%", func: undo },
    { worth: "text-box", varient: "info", width: "40%", func: handleTextBox },
    {
      worth: t("noun:settings"),
      id: "control-settings-btn",
      varient: "warning",
      width: "30%",
      func: isRemote ? handleShowSettingsRemote : handleShowSettings,
    },
    {
      worth: 26,
      varient: "info",
      width: "20%",
      func: setCurrentScoreInput,
    },
    {
      worth: 1,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    {
      worth: 2,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    {
      worth: 3,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    { worth: 60, varient: "info", width: "20%", func: setCurrentScoreInput },
    { worth: 41, varient: "info", width: "20%", func: setCurrentScoreInput },
    {
      worth: 4,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    {
      worth: 5,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    {
      worth: 6,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    { worth: 85, varient: "info", width: "20%", func: setCurrentScoreInput },
    { worth: 45, varient: "info", width: "20%", func: setCurrentScoreInput },
    {
      worth: 7,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    {
      worth: 8,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    {
      worth: 9,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    { worth: 100, varient: "info", width: "20%", func: setCurrentScoreInput },
    { worth: "", varient: "info", width: "10%" },
    { worth: t("noun:clear"), varient: "warning", width: "30%", func: clr },
    {
      worth: 0,
      varient: "success",
      width: "20%",
      func: addDigitToScoreInput,
    },
    {
      worth: noScore ? t("noun:no-score") : t("noun:ok"),
      varient: noScore ? "danger" : "success",
      width: "30%",
      func: noScore ? handleAdd : handleAdd,
    },
    { worth: "", varient: "info", width: "10%" },
  ];

  const switchPlayer = () => {
    let _currentPlayer = currentPlayer === 1 ? 2 : 1;
    setCurrentPlayer(_currentPlayer);
    setShowFinish(false);
    if (currentScore[_currentPlayer - 1] <= 170) {
      if (delayedPlayer === undefined) setDelayedPlayer(_currentPlayer);
      setTimeout(() => {
        setDelayedPlayer(_currentPlayer);
        setShowFinish(true);
      }, 1000);
    }

    addHistory();
  };

  // useEffect(() => {
  //   if (enableFB) {
  //     updateFB({
  //       legs,
  //       sets,
  //       currentScore,
  //       currentScoreboard,
  //       currentPlayer,
  //       isFirstTo,
  //       firstTo,
  //       competitionName,
  //       firstToLegs,
  //       isFirstToLegs,
  //       firstToLegsOG,
  //       currentScoreboard,
  //       teamA,
  //       teamB,
  //       checkout,
  //       firstPlayer,
  //       currentRound,
  //     });
  //   }
  // }, [
  //   currentPlayer,
  //   currentRound,
  //   currentScoreboard,
  //   firstToLegs,
  //   isFirstToLegs,
  //   firstToLegsOG,
  //   checkout,
  //   competitionName,
  // ]);

  value = {
    scores,
    competitionName,
    setCompetitionName,
    undo,
    clr,
    handleScoreInput,
    addDigitToScoreInput,
    handleAdd,
    legs,
    setLegs,
    sets,
    setSets,
    bustFlag,
    setBustFlag,
    noScore,
    setNoScore,
    firstTo,
    setFirstTo,
    isFirstTo,
    setIsFirstTo,
    currentScore,
    setCurrentScore,
    currentScoreInput,
    setCurrentScoreInput,
    checkout,
    setCheckout,
    currentPlayer,
    setCurrentPlayer,
    firstPlayer,
    setFirstPlayer,
    showModalSettings,
    setShowModalSettings,
    handleChangeCheckout,
    handleEnter,
    isFirstToLegs,
    setIsFirstToLegs,
    firstToLegs,
    setFirstToLegs,
    switchPlayer,
    firstToLegsOG,
    changeFirstTo,
    addHistory,
    initiateDB,
    enableFB,
    setEnableFB,
    initiateDBListener,
    invalidCode,
    isRemote,
    setIsRemote,
    showModalSettingsRemote,
    setShowModalSettingsRemote,
    handleSaveSettings,
    handleResetSettings,
    updateFB,
    showFinish,
    setShowFinish,
    delayedPlayer,
    setDelayedPlayer,
  };

  useEffect(() => {
    if (currentScoreInput !== "") setNoScore(false);
    if (currentScoreInput === "") setNoScore(true);
  }, [currentScoreInput]);

  return (
    <DartsContext.Provider value={value}>{children}</DartsContext.Provider>
  );
};

DartsProvider.propTypes = {
  children: PropTypes.node,
};
