/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { usePoolContext } from "../../../hooks/PoolContext";
import ColorPicker from "../../ColorPicker";
import useTranslations from "../../../../contexts/useTranslations";

function PoolScoreboard1() {
  const t = useTranslations();
  const { teamA, teamB, currentScore, colors, setColors, handleChangeColor } =
    useScoreboardContext();
  const { fromA, fromB, raceTo, handicap, isHandicapEnabled, bottomLabel } =
    usePoolContext();

  useEffect(() => {
    setColors({ ...colors, c1: "rgb(0,0,0,0)" });
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        width: 800,
        height: 200,
        paddingTop: 50,
        background: colors.c1,
      }}
      onClick={(e) => handleChangeColor("c1", e)}
    >
      <ColorPicker />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 891 76.44">
        <defs>
          <radialGradient
            id="radial-gradient"
            cx={449.34}
            cy={554.34}
            r={445.5}
            fx={449.34}
            fy={554.34}
            gradientTransform="matrix(1 0 0 .76 -3.84 -396.35)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#557ec0" />
            <stop offset={1} stopColor="#232425" />
          </radialGradient>
          <style>
            {
              ".cls-1,.cls-2{font-size:27.68px}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5{isolation:isolate}.cls-1,.cls-2,.cls-4,.cls-5{font-family:Arial-BoldMT,Arial}.cls-1,.cls-5{fill:#010101}.cls-2,.cls-4,.cls-6{fill:#fff}.cls-3{fill:#333;font-family:ArialMT,Arial;font-size:15.93px}.cls-4{font-size:22px}.cls-5{font-size:18.79px}"
            }
          </style>
        </defs>
        {isHandicapEnabled ? (
          <g id="Layer_4">
            <rect
              id="Layer_3"
              width={292.73}
              height={31.76}
              x={299.13}
              y={44.67}
              className="cls-6"
              rx={6.38}
              ry={6.38}
            />

            <text
              className="cls-5"
              transform="translate(445 68.09)"
              textAnchor="middle"
            >
              <tspan x={0} y={0}>
                {bottomLabel}
              </tspan>
            </text>
          </g>
        ) : null}
        <g id="Layer_1">
          <path
            d="M36.73 43.37h241.45v33.07H36.73V43.37Zm575.82 0H854v33.07H612.55V43.37Z"
            className="cls-6"
          />
          <path
            d="M19.92.04h851.16c11 0 19.92 8.92 19.92 19.92v28.81H0V19.95C0 8.95 8.92.04 19.92.04Z"
            style={{
              fill: "url(#radial-gradient)",
            }}
          />
          <path
            d="M299.12 0h69.13v48.77h-69.13V0Zm223.6.09h69.13V48.8h-69.13V.09Z"
            className="cls-6"
          />
          <text
            className="cls-2"
            transform={`translate(${isHandicapEnabled ? 135 : 150} 33.83)`}
            textAnchor="middle"
            style={{ fontSize: 25 }}
          >
            <tspan x={0} y={0}>
              {teamA}
            </tspan>
          </text>
          {isHandicapEnabled ? (
            <text className="cls-4" transform="translate(270 33.5)">
              <tspan x={0} y={0}>
                {handicap?.[0] > 0 ? "+" : null}
                {handicap?.[0]}
              </tspan>
            </text>
          ) : null}
          <text
            className="cls-2"
            transform={`translate(${isHandicapEnabled ? 727 : 742} 33.83)`}
            textAnchor="middle"
            style={{ fontSize: 25 }}
          >
            <tspan x={0} y={0}>
              {teamB}
            </tspan>
          </text>
          {isHandicapEnabled ? (
            <text className="cls-4" transform="translate(860.22 33.5)">
              <tspan x={0} y={0}>
                {handicap?.[1] > 0 ? "+" : null}
                {handicap?.[1]}
              </tspan>
            </text>
          ) : null}
          <text
            className="cls-2"
            transform="translate(445 35.16)"
            textAnchor="middle"
          >
            <tspan x={0} y={0}>
              {"Race To " + raceTo}
            </tspan>
          </text>
          <text
            className="cls-1"
            transform="translate(333.5 35.52)"
            textAnchor="middle"
          >
            <tspan x={0} y={0}>
              {currentScore[0]}
            </tspan>
          </text>
          <text
            className="cls-1"
            transform="translate(557 35.52)"
            textAnchor="middle"
          >
            <tspan x={0} y={0}>
              {currentScore[2]}
            </tspan>
          </text>
          <text className="cls-3" transform="translate(135.13 68.33)">
            <tspan x={0} y={0}>
              {fromA}
            </tspan>
          </text>
          <text className="cls-3" transform="translate(717.98 68.73)">
            <tspan x={0} y={0}>
              {fromB}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
}

export default PoolScoreboard1;
