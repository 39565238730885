/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  formatScore,
  useScoreboardContext,
} from "../../../contexts/ScoreboardContext";
import Slide from "react-reveal/Slide";
import MapCards from "../MapCards";
import useTranslations from "../../../contexts/useTranslations";

export const setSize = (property, size) => {
  if (property) return property.substring(-1, size).toUpperCase();

  return "";
};

function lightenColor(hex, percent) {
  // Ensure percent is between 0 and 100
  percent = Math.min(100, Math.max(0, percent));

  // Convert hex to RGB
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Calculate the adjustment value
  let adjust = (percent / 100) * 255;

  // Adjust and clamp each color component
  r = Math.min(255, Math.round(r + adjust));
  g = Math.min(255, Math.round(g + adjust));
  b = Math.min(255, Math.round(b + adjust));

  // Convert back to hex and return
  return (
    "#" +
    r.toString(16).padStart(2, "0") +
    g.toString(16).padStart(2, "0") +
    b.toString(16).padStart(2, "0")
  );
}

function ScoreboardGraphic10(props) {
  const {
    currentScore,
    colors,
    setColors,
    teamA,
    teamB,
    time,
    timeIsRed,
    handleChangeColor,
    addedTime,
    currentSport,
    showTotalScore,
    crest,
  } = useScoreboardContext();

  const [teamHome, setTeamHome] = useState();
  const [teamAway, setTeamAway] = useState();
  const t = useTranslations();

  useEffect(() => {
    setTeamHome(setSize(teamA, 3));
  }, [teamA]);

  useEffect(() => {
    setTeamAway(setSize(teamB, 3));
  }, [teamB]);

  useEffect(() => {
    // let color = colors;
    setColors((color) => {
      return {
        ...color,
        c1: "#02163d",
        c2: "#9e1800",
        c3: "#9e1800",
        c4: "#fff",
        c5: "#fff",
        c6: "#fff",
        c7: "#fff",
        c8: "#fff",
      };
    });
  }, []);

  const addedTimeComponent = () => {
    return (
      <Slide left duration={1500} when={addedTime > 0}>
        <div
          style={{
            width: 46,
            height: 44,
            backgroundColor: "#fff",
            position: "absolute",
            left: 668,
            top: 430,
            zIndex: -1,
          }}
        >
          <p
            className="text-center"
            style={{
              color: "#af2a2d",
              fontSize: 30,
              marginLeft: -4,
              fontFamily: "'Exo 2', sans-serif",
            }}
          >
            +{addedTime}
          </p>
        </div>
      </Slide>
    );
  };

  const totalScore = () => {
    return (
      <Slide down duration={1500} when={showTotalScore}>
        <div
          style={{
            width: 84,
            height: 40,
            backgroundColor: "#fff",
            position: "absolute",
            left: 313,
            top: 88,
            zIndex: -1,
          }}
        >
          <p
            className="prefix__st6 text-center"
            style={{
              color: "#000",
              fontSize: 30,
              marginLeft: -4,
              marginTop: -2,
            }}
          >
            {(currentScore?.[0] || 0) * 3 + (currentScore?.[1] || 0)}
          </p>
        </div>
        <div
          style={{
            width: 83,
            height: 40,
            backgroundColor: "#fff",
            position: "absolute",
            left: 429.5,
            top: 88,
            zIndex: -1,
          }}
        >
          <p
            className="prefix__st6 text-center"
            style={{
              color: "#000",
              fontSize: 30,
              marginLeft: -4,
              marginTop: -2,
            }}
          >
            {(currentScore?.[2] || 0) * 3 + (currentScore?.[3] || 0)}
          </p>
        </div>
      </Slide>
    );
  };

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 630 200"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1={372.84}
            x2={378.25}
            y1={41.39}
            y2={10.39}
            gradientTransform="matrix(1 0 0 -1 0 56)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor={colors.c2} />
            <stop offset={1} stopColor={lightenColor(colors.c2, 12)} />
          </linearGradient>
          <linearGradient
            id="linear-gradient1.5"
            x1={372.84}
            x2={378.25}
            y1={41.39}
            y2={10.39}
            gradientTransform="matrix(1 0 0 -1 0 56)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor={colors.c3} />
            <stop offset={1} stopColor={lightenColor(colors.c3, 12)} />
          </linearGradient>
          <linearGradient
            xlinkHref="#linear-gradient"
            id="linear-gradient-2"
            x1={117.77}
            x2={123.35}
            y1={41.76}
            y2={9.82}
          />
          <linearGradient
            id="linear-gradient-3"
            x1={444.45}
            x2={440.96}
            y1={100.94}
            y2={97.13}
            gradientTransform="matrix(0 1 1 0 144.94 -415.9)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#ffeb85" />
            <stop offset={1} stopColor="#ffd585" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1={244.94}
            x2={243.04}
            y1={42.58}
            y2={42.42}
            gradientTransform="matrix(1 0 0 -1 0 56)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#ffeb85" />
            <stop offset={1} stopColor="#ffd585" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            x1={244.94}
            x2={243.04}
            y1={16.58}
            y2={16.42}
            gradientTransform="matrix(1 0 0 -1 0 56)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#ffeb85" />
            <stop offset={1} stopColor="#ffd585" />
          </linearGradient>
          <radialGradient
            id="radial-gradient"
            cx={15.16}
            cy={332.71}
            r={1}
            fx={15.16}
            fy={332.71}
            gradientTransform="matrix(-96 0 0 57.74 2085 -19189.75)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor={lightenColor(colors.c1, 25)} />
            <stop offset={1} stopColor={colors.c1} />
          </radialGradient>
          <radialGradient
            id="radial-gradient-2"
            cx={14.8}
            cy={321.85}
            r={1}
            fx={14.8}
            fy={321.85}
            gradientTransform="matrix(73.5 0 0 -44.21 -843.5 14238.49)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor={lightenColor(colors.c1, 25)} />
            <stop offset={1} stopColor={colors.c1} />
          </radialGradient>

          <style>
            {`.cls-1{fill:none}.cls-3{font-family:'Exo 2', sans-serif}.cls-4{fill:#fff}.cls-3{font-size:36px;isolation:isolate}`}
          </style>
        </defs>
        <g id="Layer_1">
          <path
            d="M315 3h120v48H315z"
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
            style={{
              fill: "url(#linear-gradient1.5)",
            }}
          />
          <path
            d="M54 3h132v48H54z"
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            style={{
              fill: "url(#linear-gradient-2)",
            }}
          />
          <path
            d="M489 3h141v48H489z"
            onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            style={{
              fill: "url(#radial-gradient)",
            }}
          />
          <path
            d="M174 3h141v48H174z"
            onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            style={{
              fill: "url(#radial-gradient-2)",
            }}
          />
          <path
            d="M242 24.83h4v4h-4z"
            style={{
              fill: "url(#linear-gradient-3)",
            }}
            transform="rotate(-45 244 26.822)"
          />
          <path
            d="M242 24.83h4v4h-4z"
            style={{
              fill: "rgba(255,255,255,.2)",
            }}
            transform="rotate(-45 244 26.822)"
          />
          <path
            d="M243.5 7h1v13h-1z"
            style={{
              fill: "url(#linear-gradient-4)",
            }}
          />
          <path
            d="M243.5 33h1v13h-1z"
            style={{
              fill: "url(#linear-gradient-5)",
            }}
          />
          {crest[0] || crest[1] ? (
            <>
              <path d="M0 0h54v54H0z" className="cls-4" />
              <path d="M3 3h48v48H3z" className="cls-1" />{" "}
            </>
          ) : null}
          {crest[0] || crest[1] ? (
            <>
              <path d="M435 0h54v54h-54z" className="cls-4" />
              <path d="M438 3h48v48h-48z" className="cls-1" />
            </>
          ) : null}
        </g>
        <g id="Layer_2">
          <text
            style={{
              fontFamily: "'Exo 2', sans-serif",
              fontSize: 36,
              isolation: "isolate",
              fill: colors.c5,
            }}
            onClick={(e) => handleChangeColor("c5", e, [0, 0])}
            transform="translate(515.12 39.91)"
          >
            {time}
          </text>
          <text
            className="cls-3"
            transform="translate(208.88 39.91)"
            textAnchor="middle"
            fill={colors.c4}
            onClick={(e) => handleChangeColor("c4", e, [0, 0])}
          >
            <tspan x={0} y={0}>
              {currentScore[0]}
            </tspan>
          </text>
          <text
            className="cls-3"
            transform="translate(280.28 39.91)"
            textAnchor="middle"
            fill={colors.c4}
            onClick={(e) => handleChangeColor("c4", e, [0, 0])}
          >
            <tspan x={0} y={0}>
              {currentScore[2]}
            </tspan>
          </text>
          <text
            className="cls-3"
            transform="translate(115 39.91)"
            textAnchor="middle"
            fill={colors.c6}
            onClick={(e) => handleChangeColor("c6", e, [0, 0])}
          >
            <tspan x={0} y={0}>
              {teamHome}
            </tspan>
          </text>
          <text
            className="cls-3"
            transform="translate(374.87 39.91)"
            textAnchor="middle"
            fill={colors.c7}
            onClick={(e) => handleChangeColor("c7", e, [0, 0])}
          >
            <tspan x={0} y={0}>
              {teamAway}
            </tspan>
          </text>
        </g>
        <MapCards x={54} y={55} team={0} gap={[30, 8]} />

        <MapCards x={315} y={55} team={1} gap={[30, 8]} />
      </svg>
      {addedTimeComponent()}
      {crest[0] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 1`}
          style={{
            position: "absolute",
            top: 473,
            left: 76,
            display: "box",
            width: 40,
            zAxis: 100,
          }}
          src={crest[0]}
        />
      )}
      {crest[1] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 2`}
          style={{
            position: "absolute",
            top: 473,
            left: 490,
            display: "box",
            width: 40,
            zAxis: 100,
          }}
          src={crest[1]}
        />
      )}
    </div>
  );
}

export default ScoreboardGraphic10;
