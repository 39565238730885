import React from "react";
import { AuthProvider } from "../contexts/AuthContext";
import { SubscriptionProvider } from "../contexts/SubscriptionContext";
import Routes from "./Routes";
import IntlProvider from "../contexts/IntlProvider";
import FirebaseProvider from "../contexts/FirebaseProvider";

function App() {
  return (
    <div>
      <FirebaseProvider>
        <IntlProvider>
          <AuthProvider>
            <SubscriptionProvider>
              <Routes />
            </SubscriptionProvider>
          </AuthProvider>
        </IntlProvider>
      </FirebaseProvider>
    </div>
  );
}

export default App;
