/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { useScoreboardContext } from "../../../contexts/ScoreboardContext";
import { useDartsContext } from "../../hooks/DartsContext";
import { useSubscription } from "../../../contexts/SubscriptionContext";
import useTranslations from "../../../contexts/useTranslations";
import Finishes from "../../../config/darts_finishes.json";

function searchScore(scoreToFind, data) {
  // Filter the array of objects to find the object with the desired score
  const result = data.find((item) => item.score === scoreToFind);

  // If the result is found, return the object, otherwise return null
  return result ? result.finish : [];
}

function DartsScoreboard2() {
  const t = useTranslations();
  const { savedSettings } = useSubscription();
  const {
    legs,
    sets,
    currentScore,
    currentPlayer,
    firstPlayer,
    isFirstTo,
    firstTo,
    competitionName,
    delayedPlayer,
    showFinish,
    isRemote,
  } = useDartsContext();

  const { teamA, teamB, handleChangeColor, colors, setColors } =
    useScoreboardContext();

  useEffect(() => {
    if (savedSettings?.colors) {
      setColors({ ...savedSettings.colors });
    } else {
      setColors((cols) => {
        return {
          ...cols,
          c1: "#e50e26",
          c2: "#000",
          c3: "#fff",
          c4: "#000",
        };
      });
    }
  }, [savedSettings]);

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
          enableBackground: "new 0 0 1537 435",
        }}
        viewBox="0 0 1537 435"
      >
        <style>
          {
            '.st3{font-family:"Arial-BoldMT"}.st4{font-size:40.9867px}.st6{fill:none}.st7,.st8{fill:none;stroke:#fff;stroke-width:.5}.st9{font-size:55px}.st10{font-size:48.9192px}'
          }
        </style>
        <defs>
          <path id="SVGID_1_" d="M0 0h1537v435H0z" />
        </defs>
        <clipPath id="SVGID_00000032616077698988391880000005673970385715461543_">
          <use
            xlinkHref="#SVGID_1_"
            style={{
              overflow: "visible",
            }}
          />
        </clipPath>
        <g
          style={{
            clipPath:
              "url(#SVGID_00000032616077698988391880000005673970385715461543_)",
          }}
        >
          <path
            d="M533 0h1004v435H533z"
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
          />
          <path
            d="M568 35h934v55H568z"
            className="st1"
            fill={colors.c1}
            onClick={(e) => handleChangeColor("c1", e, [0, 0])}
          />
          <text
            className="st3 st4"
            transform="translate(634.99 78.376)"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          >
            {isFirstTo ? "First to " : "Best of "}
            {firstTo}
          </text>
          <text
            className="st3 st4"
            transform="translate(1301.086 78.909)"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          >
            {"Legs"}
          </text>
          <text
            className="st3 st4"
            transform="translate(1193 78.909)"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          >
            {"Sets"}
          </text>
          <text
            className="st3"
            style={{
              fontSize: 40,
            }}
            transform="translate(568 398.92)"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          >
            {competitionName}
          </text>
          <path d="M578 115h92v92h-92z" className="st6" />
          <path d="M569 106h932v110H569z" className="st7" />
          <path
            d="M1193 105h309v112h-309z"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          />
          <text
            className="st3 st4"
            transform="translate(1240 175.458)"
            textAnchor="middle"
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
          >
            {sets[0]}
          </text>
          <text
            className="st3 st4"
            transform="translate(1345 175.458)"
            textAnchor="middle"
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
          >
            {legs[0]}
          </text>
          <text
            className="st3 st4"
            transform="translate(1405.284 175.458)"
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
          >
            {currentScore[0]}
          </text>
          <path d="M578 242h92v92h-92z" className="st6" />
          <path d="M569 233h932v110H569z" className="st7" />
          <path
            d="M1193 232h309v112h-309z"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          />
          <text
            className="st3 st4"
            transform="translate(1240 302.459)"
            textAnchor="middle"
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
          >
            {sets[1]}
          </text>
          <text
            className="st3 st4"
            transform="translate(1345 302.459)"
            textAnchor="middle"
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
          >
            {legs[1]}
          </text>
          <text
            className="st3 st4"
            transform="translate(1404.608 303.541)"
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
          >
            {currentScore[1]}
          </text>

          {firstPlayer === 1 ? (
            <circle
              cx={1156}
              cy={161}
              r={10.5}
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            />
          ) : (
            <circle
              cx={1156}
              cy={288}
              r={10.5}
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            />
          )}

          {currentPlayer === 1 ? (
            <path
              d="M1502 105v112l-19.4-54.2L1502 105z"
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            />
          ) : (
            <path
              d="M1502 232v112l-19.4-54.2L1502 232z"
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            />
          )}

          <text
            className="st3 st10"
            transform="translate(610 305.481)"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          >
            {teamB}
          </text>

          <text
            className="st3 st10"
            transform="translate(610 176.836)"
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          >
            {teamA}
          </text>
        </g>
      </svg>
      <Finish
        colors={colors}
        currentPlayer={delayedPlayer}
        showFinish={showFinish}
        finish={currentScore[delayedPlayer - 1]}
        isRemote={isRemote}
      />
    </div>
  );
}

export default DartsScoreboard2;

const Finish = ({ isRemote, colors, finish, currentPlayer, showFinish }) => {
  const finishes = useMemo(() => {
    return searchScore(finish, Finishes);
  }, [finish]);

  return (
    <div
      style={{
        zIndex: -1,
        position: "relative",
        top: isRemote ? -127 : -108,
      }}
    >
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          transition: "transform 0.5s ease",
          transform: showFinish ? "translateX(0)" : "translateX(50%)",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 355.88 74"
        >
          <rect
            x={91 - 33.33 * (finishes?.length - 1)}
            y={currentPlayer === 2 ? 38 : 9}
            width={33.33 * finishes?.length}
            height="22"
            fill={colors.c1}
          />

          {finishes.map((item, i) => {
            return (
              <text
                key={i}
                transform={`translate(${107.33 + 33.3 * -i} ${
                  currentPlayer === 2 ? 53 : 24.5
                })`}
                fontSize={11.5}
                fill="#fcfcfc"
                fontFamily="Arial-BoldMT"
                textAnchor="middle"
              >
                {finishes?.[finishes.length - i - 1]}
              </text>
            );
          })}
        </svg>
      </div>
    </div>
  );
};
