/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useScoreboardContext } from "../../../contexts/ScoreboardContext";
import "../../assets/fonts/font.css";
import { useDartsContext } from "../../hooks/DartsContext";
import { useSubscription } from "../../../contexts/SubscriptionContext";
import useTranslations from "../../../contexts/useTranslations";
import { Button } from "react-bootstrap";
import Finishes from "../../../config/darts_finishes.json";

function searchScore(scoreToFind, data) {
  // Filter the array of objects to find the object with the desired score
  const result = data.find((item) => item.score === scoreToFind);

  // If the result is found, return the object, otherwise return null
  return result ? result.finish : [];
}

const DartsScoreboard = (props) => {
  const t = useTranslations();
  const { savedSettings } = useSubscription();
  const {
    legs,
    currentScore,
    currentPlayer,
    firstPlayer,
    isFirstToLegs,
    firstToLegs,
    competitionName,
    showFinish,
    delayedPlayer,
    isRemote,
  } = useDartsContext();

  const { teamA, teamB, handleChangeColor, colors, setColors } =
    useScoreboardContext();

  useEffect(() => {
    if (savedSettings && savedSettings?.colors) {
      setColors({ ...savedSettings.colors });
    } else {
      setColors((cols) => {
        return {
          ...cols,
          c1: "#8f0102",
          c2: "#fff",
          c3: "#1b9714",
          c4: "#000",
        };
      });
    }
  }, [savedSettings]);

  return (
    <div style={{ zIndex: -5, marginTop: -50 }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327 74" {...props}>
        <title>{"DartsScoreboard"}</title>
        <g data-name="Layer 1">
          <path
            d="M110 0h192v16H110z"
            fill={colors.c4}
            onClick={(e) => handleChangeColor("c4", e, [0, 0])}
          />
          <path
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            d="M110 16h123v21H110zM110 37h123v21H110z"
          />
          <path
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
            d="M233 16h69v21h-69zM233 37h69v21h-69z"
          />
          <path
            d="M110 58h192v16H110z"
            fill={colors.c4}
            onClick={(e) => handleChangeColor("c4", e, [0, 0])}
          />
          <text
            transform="translate(118.468 11.787)"
            fontSize={12}
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            fontFamily="Roboto, sans-serif"
          >
            <tspan letterSpacing="-.026em">
              {isFirstToLegs
                ? t("noun:first-to") + " "
                : t("noun:best-of") + " "}
              {firstToLegs}
            </tspan>
          </text>
          <text
            transform="translate(237.984 11.787)"
            fontSize={12}
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            fontFamily="Roboto, sans-serif"
          >
            <tspan letterSpacing="-.014em">{"Legs"}</tspan>
          </text>
          <text
            transform="translate(118.468 69.787)"
            fontSize={12}
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            fontFamily="Roboto, sans-serif"
          >
            <tspan letterSpacing="-.011em">{competitionName}</tspan>
          </text>
          <text
            transform="translate(119.468 31.787)"
            fontSize={16}
            fontFamily="Roboto, sans-serif"
          >
            <tspan letterSpacing="-.005em">{teamA}</tspan>
          </text>
          <text
            transform="translate(119.468 51.787)"
            fontSize={16}
            fontFamily="Roboto, sans-serif"
          >
            <tspan letterSpacing="-.005em">{teamB}</tspan>
          </text>
          <text
            transform="translate(243.468 51.787)"
            fontSize={16}
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            fontFamily="Roboto, sans-serif"
          >
            {legs?.[1]}
          </text>
          <text
            transform="translate(270.157 51.787)"
            fontSize={16}
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            fontFamily="Roboto, sans-serif"
          >
            {currentScore?.[1]}
          </text>
          <text
            transform="translate(243.468 33.787)"
            fontSize={16}
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            fontFamily="Roboto, sans-serif"
          >
            {legs?.[0]}
          </text>
          <text
            transform="translate(270.157 33.787)"
            fontSize={16}
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            fontFamily="Roboto, sans-serif"
          >
            {currentScore?.[0]}
          </text>
          {firstPlayer === 1 ? (
            <circle
              cx={217}
              cy={27}
              r={2}
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            />
          ) : (
            <circle cx={217} cy={47} r={2} fill={colors.c1} />
          )}
        </g>
        {currentPlayer === 1 ? (
          <>
            <path
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e, [200, 0])}
              d="M302 19h25v18h-25z"
            />
            <path
              fill={colors.c2}
              onClick={(e) => handleChangeColor("c2", e, [0, 0])}
              d="m316.243 34.243-6-6 6-6"
            />
          </>
        ) : (
          <g data-name="Layer 3">
            <path
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e, [200, 0])}
              d="M302 38h25v18h-25z"
            />
            <path
              fill={colors.c2}
              onClick={(e) => handleChangeColor("c2", e, [0, 0])}
              d="m317.243 52.243-6-6 6-6"
            />
          </g>
        )}
      </svg>

      <Finish
        currentPlayer={delayedPlayer}
        showFinish={showFinish}
        finish={currentScore?.[delayedPlayer - 1]}
        isRemote={isRemote}
      />
      {/* <Button onClick={() => setShowFinish((f) => !f)}>Finish</Button> */}
    </div>
  );
};

export default DartsScoreboard;

const Finish = ({ currentPlayer, showFinish, finish, isRemote }) => {
  const finishes = useMemo(() => {
    return searchScore(finish, Finishes);
  }, [finish]);

  return (
    <div
      style={{
        zIndex: -1,
        position: "relative",
        top: isRemote ? -120 : -105,
      }}
    >
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          transition: "transform 0.5s ease",
          transform: showFinish ? "translateX(0)" : "translateX(50%)",
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327 74">
          <>
            <rect
              x={65 - 28 * (finishes?.length - 1)}
              y={currentPlayer === 2 ? "37.2" : "18"}
              width={52 * finishes?.length * (showFinish ? 1 : 0.7)}
              height="18"
              fill="#8f0102"
            />
            {finishes.map((item, i) => {
              return (
                <text
                  key={i}
                  transform={`translate(${88 + 30 * -i} ${
                    currentPlayer === 2 ? 50.5 : 31
                  })`}
                  fontSize={12}
                  fill="#fcfcfc"
                  fontFamily="Roboto, sans-serif"
                  textAnchor="middle"
                >
                  {finishes?.[finishes.length - i - 1]}
                </text>
              );
            })}
          </>
        </svg>
      </div>
    </div>
  );
};
