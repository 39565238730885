import React from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { useTennisContext } from "../TennisContext";

function TennisDGreenScoreboard() {
  const { teamA, teamB } = useScoreboardContext();

  const { sets, legs, currentScore, isFirstServer, notice, showNotice } =
    useTennisContext();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1210.38 264.83">
      <defs>
        <style>
          {
            ".cls-4,.cls-5{fill:#e7ec9d}.cls-6{fill:#0c7880}.cls-5,.cls-8,.cls-9{font-size:73.63px;font-family:Roboto-Medium,Roboto}.cls-8{fill:#fff}.cls-9{fill:#fafafa}"
          }
        </style>
      </defs>
      <g id="Layer_1" data-name="Layer 1">
        <path
          style={{
            fill: "#004553",
          }}
          d="M0 52.77h1210.38v212.06H0z"
          id="PlayersBG"
        />
        <text className="cls-8" transform="translate(16.8 139.24)">
          <tspan x={0} y={0}>
            {teamA}
          </tspan>
        </text>
        <path
          id="Games"
          className="cls-6"
          d="M937.94 52.77h111.92v212.06H937.94z"
        />
        <text className="cls-9" transform="translate(971.51 138.11)">
          <tspan x={0} y={0}>
            {legs[0]}
          </tspan>
        </text>
        <text className="cls-9" transform="translate(970.04 239.06)">
          <tspan x={0} y={0}>
            {legs[1]}
          </tspan>
        </text>
        <path
          id="PointsBG"
          style={{
            fill: "#003b3e",
          }}
          d="M1049.86 52.77h160.52v212.06h-160.52z"
        />
        <text
          className="cls-5"
          textAnchor="middle"
          transform="translate(1130 138.35)"
        >
          <tspan x={0} y={0}>
            {typeof currentScore?.[0] === "object" ? 0 : currentScore?.[0]}
          </tspan>
        </text>
        <text
          className="cls-5"
          textAnchor="middle"
          transform="translate(1130 239.96)"
        >
          <tspan x={0} y={0}>
            {typeof currentScore?.[1] === "object" ? 0 : currentScore?.[1]}
          </tspan>
        </text>
        <text className="cls-8" transform="translate(17.62 240.86)">
          <tspan x={0} y={0}>
            {teamB}
          </tspan>
        </text>
        <text className="cls-8" transform="translate(834.12 137.45)">
          <tspan x={0} y={0}>
            {sets[0]}
          </tspan>
        </text>
        <text
          transform="translate(835.85 242.74)"
          style={{
            fill: "#a6b4b0",
            fontFamily: "Roboto-Medium,Roboto",
            fontSize: "73.63px",
          }}
        >
          <tspan x={0} y={0}>
            {sets[1]}
          </tspan>
        </text>
        {isFirstServer ? (
          <ellipse
            className="cls-4"
            cx={795.03}
            cy={113.49}
            rx={14.39}
            ry={15.13}
            id="circle-p1"
          />
        ) : (
          <ellipse
            className="cls-4"
            cx={795.03}
            cy={216.57}
            rx={14.39}
            ry={15.13}
            id="circle-p2"
          />
        )}
        <path
          style={{
            fill: "none",
            opacity: 0.04,
            stroke: "#fff",
            strokeMiterlimit: 10,
          }}
          d="M1.39 160.28h1208.99"
        />
      </g>
      {showNotice ? (
        <g id="Note">
          <path id="NoticeBG" className="cls-6" d="M0 0h368.62v52.77H0z" />
          <text
            transform="translate(76.97 42.6)"
            style={{
              fontSize: "44.18px",
              fill: "#fff",
              fontFamily: "Roboto-Medium,Roboto",
            }}
          >
            <tspan x={0} y={0}>
              {notice}
            </tspan>
          </text>
        </g>
      ) : null}
    </svg>
  );
}

export default TennisDGreenScoreboard;
