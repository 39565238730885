/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useScoreboardContext } from "../../../contexts/ScoreboardContext";
import { db } from "../../../firebase";
import { off, onValue, ref, set, update } from "firebase/database";

const BasketballContext = React.createContext();

export function useBasketballContext() {
  return useContext(BasketballContext);
}

{/* prettier-ignore */}
export function BasketballProvider(props) {
  const [period, setPeriod] = useState("1ST");
  const [scoreA, setScoreA] = useState(0);
  const [scoreB, setScoreB] = useState(0);
  const [foulsACount, setFoulsACount] = useState(0);
  const [foulsBCount, setFoulsBCount] = useState(0);
  const [hasShotClock, setHasShotClock] = useState(false);
  const [shotClockTime, setShotClockTime] = useState(30);
  const [shotClockStarted, setShotClockStarted] = useState(false);
  const [shotClockDuration, setShotClockDuration] = useState(30);
  const [timeoutsCountA, setTimeoutsCountA] = useState(0);
  const [timeoutsCountB, setTimeoutsCountB] = useState(0);
  const [subsA, setSubsA] = useState(0);
  const [subsB, setSubsB] = useState(0);
  const [announcement, setAnnouncement] = useState("");
  const [showAnnouncement, setShowAnnouncement] = useState();
  const [currentTeam, setCurrentTeam] = useState(0);
  const [showFoulsCounter, setShowFoulsCounter] = useState(true);
  const [resetFoulsFrequency, setResetFoulsFrequency] = useState("1/4");
  const [numFoulsPerPeriod, setNumFoulsPerPeriod] = useState(7);
  const [history, setHistory] = useState([]);

  const {
    enableFB,
    seconds,
    setSeconds,
    minutes,
    setMinutes,
    halfLength,
    setHalfLength,
    currentUser,
    generatedCode,
    setGeneratedCode,
    currentSport,
    setEnableFB,
    started,
    setInvalidCode,
    setCurrentSport,
    setStarted,
    timeForward,
    setTimeForward,
    startTime,
    setStartTime,
    teamA,
    teamB,
    setTeamA,
    setTeamB,
    isPhoneController,
    setData,
  } = useScoreboardContext();

  const updateFB = (updateObject) => {
    if (enableFB) {
      const userRef = ref(db, `scores/${currentUser.uid}/${generatedCode}`);
      update(userRef, updateObject);
    }
  };

  const handleAnnouncement = (announcement, team, duration) => {
    if (showAnnouncement !== true) {
      storeHistory();
      setShowAnnouncement(true);

      setAnnouncement(announcement);
      setCurrentTeam(team);
      console.log("showAnnouncement: ", true);

      setTimeout(() => {
        setShowAnnouncement(false);
        updateFB({ showAnnouncement: false });
      }, duration);

      let _timeoutsCountA = timeoutsCountA;
      let _timeoutsCountB = timeoutsCountB;

      let _foulsACount = foulsACount;
      let _foulsBCount = foulsBCount;

      switch (announcement) {
        case "TIMEOUT":
          team === 0 ? _timeoutsCountA++ : _timeoutsCountB++;
          break;
        case "FOUL":
          team === 0 ? _foulsACount++ : _foulsBCount++;
          break;
        default:
          break;
      }

      setTimeoutsCountA(_timeoutsCountA);
      setTimeoutsCountB(_timeoutsCountB);

      setFoulsACount(_foulsACount);
      setFoulsBCount(_foulsBCount);

      updateFB({
        showAnnouncement: true,
        announcement: announcement,
        currentTeam: team,
        timeoutsCountA: _timeoutsCountA,
        timeoutsCountB: _timeoutsCountB,
        foulsACount: _foulsACount,
        foulsBCount: _foulsBCount,
      });
    }
  };

  const handleChangeScoreA = (amount) => {
    let _scoreA = scoreA;
    _scoreA = _scoreA + amount;
    setScoreA(_scoreA);
    updateFB({ scoreA: _scoreA });
    storeHistory();
  };

  const handleChangeScoreB = (amount) => {
    let _scoreB = scoreB;
    _scoreB = _scoreB + amount;
    setScoreB((scoreB) => scoreB + amount);
    updateFB({ scoreB: _scoreB });
    storeHistory();
  };

  const handleChangePeriod = () => {
    setStarted(false);
    var newPeriod = period;
    switch (period) {
      case "1ST":
        newPeriod = "2ND";
        break;
      case "2ND":
        newPeriod = "3RD";
        break;
      case "3RD":
        newPeriod = "4TH";
        break;
      case "4TH":
        break;
      default:
        "1ST";
        break;
    }

    if (resetFoulsFrequency == "1/2") {
      if (newPeriod === "3RD") {
        setFoulsACount(0);
        setFoulsBCount(0);
      }
    } else {
      setFoulsACount(0);
      setFoulsBCount(0);
    }

    storeHistory();
    updateFB({
      period: newPeriod,
      foulsACount: 0,
      foulsBCount: 0,
      startTime: new Date().getTime(),
      time: halfLength + ":00",
      timeStop: { minutes: halfLength, seconds: 0 },
    });
    setMinutes(halfLength);
    setSeconds(0);
    setPeriod(newPeriod);
    // setStarted(false);
  };

  const undoHistory = () => {
    if (history.length > 0) {
      const lastItem = history[history.length - 1];
      setPeriod(lastItem.period);
      setScoreA(lastItem.scoreA);
      setScoreB(lastItem.scoreB);
      setFoulsACount(lastItem.foulsACount);
      setFoulsBCount(lastItem.foulsBCount);
      setTimeoutsCountA(lastItem.timeoutsCountA);
      setTimeoutsCountB(lastItem.timeoutsCountB);
      setSubsA(lastItem.subsA);
      setSubsB(lastItem.subsB);

      updateFB({
        period: lastItem.period,
        scoreA: lastItem.scoreA,
        scoreB: lastItem.scoreB,
        foulsACount: lastItem.foulsACount,
        foulsBCount: lastItem.foulsBCount,
        timeoutsCountA: lastItem.timeoutsCountA,
        timeoutsCountB: lastItem.timeoutsCountB,
        subsA: lastItem.subsA,
        subsB: lastItem.subsB,
      });

      setHistory((prevHistory) => {
        return prevHistory.slice(0, -1);
      });
    }
  };

  useEffect(() => {
    let unsubscribe;
    if (currentSport === "bb" && enableFB) {
      initiateDBListener(generatedCode).then((unsub) => {
        unsubscribe = unsub;
      });
    }

    return () => {
      console.log("unsubscribe");
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [currentSport, enableFB]);

  const storeHistory = () => {
    const newHistoryItem = {
      period,
      scoreA,
      scoreB,
      foulsACount,
      foulsBCount,
      timeoutsCountA,
      timeoutsCountB,
      subsA,
      subsB,
    };

    setHistory((prevHistory) => {
      return [...prevHistory, newHistoryItem];
    });
  };

  const resetScoreboard = () => {
    setPeriod("1ST");
    setMinutes(halfLength);
    setSeconds(0);
    setScoreA(0);
    setScoreB(0);
    setShotClockTime(shotClockDuration);
    setFoulsACount(0);
    setFoulsBCount(0);

    updateFB({
      period: "1ST",
      minutes: halfLength,
      seconds: 0,
      scoreA: 0,
      scoreB: 0,
      shotClockTime: shotClockDuration,
      started: false,
      foulsACount: 0,
      foulsBCount: 0,
      time: halfLength + ":00",
      startTime: new Date().getTime(),
      timeStop: { minutes: halfLength, seconds: 0 },
      halfLength,
    });
  };

  useEffect(() => {
    setTimeForward(false);
    let extra = {};
    if (!isPhoneController) {
      extra = {
        timeStop: { minutes: 10, seconds: 0 },
        startTime: new Date().getTime(),
        halfLength: 10,
      };
    }
    updateFB({ timeForward: false, ...extra });
  }, [isPhoneController]);

  const initiateDB = (code) => {
    console.log("initateDB ran");
    console.log({ uid: currentUser.uid });
    const userRef = ref(
      db,
      `scores/${currentUser.uid}/${code || generatedCode}`
    );

    set(userRef, {
      currentSport,
      period,
      scoreA,
      scoreB,
      started,
      teamA,
      teamB,
      foulsACount,
      foulsBCount,
      hasShotClock,
      shotClockTime,
      shotClockStarted,
      shotClockDuration,
      timeoutsCountA,
      timeoutsCountB,
      announcement,
      showAnnouncement: false,
      currentTeam,
      timeForward,
      seconds,
      minutes,
      timeStop: { minutes, seconds },
      startTime: 0,
      halfLength,
    });

    setEnableFB(true);
  };

  const initiateDBListener = async (genCode) => {
    const code = genCode || generatedCode;
    setGeneratedCode(code);

    try {
      const scoresRef = ref(db, "scores/" + currentUser.uid + "/" + code);

      const unsubscribe = onValue(scoresRef, (snapshot) => {
        if (snapshot.exists()) {
          console.log("listener 2");
          const data = snapshot.val();
          console.log({ data });
          setData(data);
          if (data?.currentSport !== undefined)
            setCurrentSport(data.currentSport);
          if (data?.period !== undefined) setPeriod(data.period);
          if (data?.scoreA !== undefined) setScoreA(data.scoreA);
          if (data?.scoreB !== undefined) setScoreB(data.scoreB);
          if (data?.teamA !== undefined) setTeamA(data.teamA);
          if (data?.teamB !== undefined) setTeamB(data.teamB);
          if (data?.foulsACount !== undefined) setFoulsACount(data.foulsACount);
          if (data?.foulsBCount !== undefined) setFoulsBCount(data.foulsBCount);
          if (data?.hasShotClock !== undefined)
            setHasShotClock(data.hasShotClock);
          if (data?.shotClockTime) setShotClockTime(data.shotClockTime);
          if (data?.shotClockStarted !== undefined)
            setShotClockStarted(data.shotClockStarted);
          if (data?.shotClockDuration)
            setShotClockDuration(data.shotClockDuration);
          if (data?.timeoutsCountA !== undefined)
            setTimeoutsCountA(data.timeoutsCountA);
          if (data?.timeoutsCountB !== undefined)
            setTimeoutsCountB(data.timeoutsCountB);
          if (data?.announcement !== undefined)
            setAnnouncement(data.announcement);
          if (data?.showAnnouncement !== undefined)
            setShowAnnouncement(data?.showAnnouncement);
          if (data?.currentTeam !== undefined) setCurrentTeam(data.currentTeam);
          if (data?.timeForward !== undefined) setTimeForward(data.timeForward);
          if (data?.halfLength !== undefined) {
            setHalfLength(data.halfLength);
          } else {
            setHalfLength(10);
          }

          // console.log({ _startTime, data: data?.startTime });

          // if (bcast != null) bcast.postMessage(["score", data.score]);
        } else {
          setInvalidCode(true);
        }
      });

      return unsubscribe;
    } catch (er) {
      console.log(er);
      console.log("error caught");
    }

    return () => {};
  };

  // useEffect(() => {
  //   if (data) {
  //     setStartTime(data.startTime);
  //     if (data?.started === true && data?.startTime !== startTime) {
  //       if (
  //         data?.timeStop.seconds !== undefined &&
  //         data?.timeStop.minutes !== undefined
  //       ) {
  //         const currentTime = new Date();
  //         const elapsedTime = Math.abs(
  //           Math.floor((currentTime - new Date(data.startTime)) / 1000)
  //         ); // in seconds

  //         // Calculate minutes and remaining seconds
  //         const newMinutes = Math.floor(elapsedTime / 60);
  //         const newSeconds = elapsedTime % 60;

  //         setSeconds(newSeconds);
  //         setMinutes(newMinutes);
  //         if (data?.started !== undefined) {
  //           setStarted(data.started);
  //         }
  //       }
  //     }
  //     // if clock is stopped
  //     else if (data?.started === false && data?.startTime !== startTime) {
  //       if (data?.started !== undefined) {
  //         setStarted(data.started);
  //       }

  //       setSeconds(data?.timeStop?.seconds);
  //       setMinutes(data?.timeStop?.minutes);
  //     }
  //   }
  // }, [data, startTime]);

  useEffect(() => {
    if (showAnnouncement === true) {
      setTimeout(() => {
        setShowAnnouncement(false);
      }, 5000);
    }
  }, [showAnnouncement]);

  const [isRunningLocally, setIsRunningLocally] = useState(false);

  const value = {
    storeHistory,
    period,
    handleChangePeriod,
    scoreA,
    handleChangeScoreA,
    scoreB,
    handleChangeScoreB,
    timeoutsCountA,
    setTimeoutsCountA,
    timeoutsCountB,
    setTimeoutsCountB,
    subsA,
    setSubsA,
    subsB,
    setSubsB,
    undoHistory,
    handleAnnouncement,
    announcement,
    showAnnouncement,
    currentTeam,
    hasShotClock,
    setHasShotClock,
    shotClockDuration,
    setShotClockDuration,
    shotClockTime,
    setShotClockTime,
    shotClockStarted,
    setShotClockStarted,
    resetScoreboard,
    foulsACount,
    setFoulsACount,
    foulsBCount,
    setFoulsBCount,
    initiateDB,
    numFoulsPerPeriod,
    setNumFoulsPerPeriod,
    showFoulsCounter,
    setShowFoulsCounter,
    resetFoulsFrequency,
    setResetFoulsFrequency,
  };

  return (
    <BasketballContext.Provider value={value}>
      {props.children}
    </BasketballContext.Provider>
  );
}

BasketballProvider.propTypes = {
  children: PropTypes.any,
};
